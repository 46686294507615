import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {HttpService} from "../../services/http.service";
import {ChatService} from "../../services/chat.service";
import {Message} from "twilio-chat/lib/message";
import {Chat} from "../../interface/chat";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  title = 'AgaCare';
  public email = '';
  public pseudoName = '';
  public changePassword = false;
  public username = '';
  public getChannelBySid;
  public getToken;
  public startChat = 'Démarrer le chat';
  public getIdentity;
  public loading = false;
  public welcome = false;
  public createAccount = true;
  public verifyCode = false;
  public hasPassword = false;
  public createPassword = false;
  public validateEmail = true;
  public httpSuccess = false;
  public httpError = false;
  public validateCode = false;
  public validPassword = false;
  public validateUsername = false;
  public forgetPassword = false;
  public successMessage = '';
  public infoMessage = '';
  public errorMessage = '';
  public emptyCreatePassword = false;
  public showPassword = false;
  public messages: Message[] = [];
  constructor( private router: Router,
               private chatService: ChatService,
               private httpService: HttpService) {

  }

  ngOnInit(): void {
      this.getToken =  localStorage.getItem('agaToken');
      this.getChannelBySid =  localStorage.getItem('channelSid');
      this.getIdentity=  localStorage.getItem('agaIdentity');
      if(this.getToken && this.getChannelBySid &&  this.getIdentity  ){
          this.router.navigateByUrl('chat');
      }
  }

  createOrJoin(email){
      if(email != '') {
          let channelSid = this.getChannelBySid? this.getChannelBySid : null;
          //console.log('Requete encour de traitement! Veuillez patientez... ');
          this.httpService.post({identity:email,channelSid:channelSid},'chat-token/generate').subscribe(data => {
              //console.log('Done http request!!! ');
              //console.log(data);
              this.httpError = false;
              RegisterComponent.storeItems(data).then(next => {
                  this.router.navigateByUrl('chat');
              });
          }, error => {
              //console.log('Done http request!!! ');
              //console.log(error);
              alert('Server Error! Please try again');
          });
      }else{
          alert('Enter email');
      }
  }

  checkMail(email){
      if(RegisterComponent.emailValidation(email)){
          this.loading = true;
          this.validateEmail = true;
          this.httpService.post({email:email},'check/email').subscribe(data => {
              this.loading = false;
              this.httpError = false;
              if(data.success){
                  this.welcome = false;
                      if(data.hasAccount && data.user.email_verified_at){
                          this.email = data.user.email;
                          this.username = data.user.name;
                          if(!data.user.password){
                              this.createPassword = true;
                          }else
                          this.hasPassword = true;
                      }else{
                          this.email = data.user.email;
                          this.username = data.user.username;
                          this.verifyCode = true;
                      }
              }
          }, error => {
              this.errorMessage = 'Error occurred while verifying email';
              this.loading = false;
              this.httpError = true;
          });
      } else
          this.validateEmail = false; 
  }

  login(email,password){
      this.loading = true;
      this.httpSuccess = false;
      this.httpSuccess = false;
      this.httpService.post({email:email,password:password},'login').subscribe(data => {
          this.loading = false;
          this.httpError = false;
          if(data.success){
              this.getChannelBySid = data.channelSid;
              RegisterComponent.storeItems(data).then(() =>{
                  this.router.navigateByUrl('chat');
              });
          }else{
              this.httpError = true;
              this.errorMessage = data.message;
          }
      }, error => {
          if(error.error.verifyAccount){
              this.welcome = false;
              this.verifyCode = true;
              this.loading = false;
              this.httpSuccess = true;
              this.email = error.error.user.email;
              this.successMessage = error.error.message;
              this.changePassword = false;
          }else{
              this.errorMessage = error.error.message//'Erreur lors de traitement de vérification code';
              this.loading = false;
              this.httpError = true;
          }
      });
  }

  createNewAccount(username, email,password){
      if(RegisterComponent.emailValidation(email)){
          if (password.length < 6) {
              this.errorMessage = 'Mot de passe doit avoir au moins 6 caractére!'
          } else
           this.loading = true;
           this.httpService.post({
              recover:false,
              email:email,
              username: username,
              password: password
          }, 'register').subscribe(data => {
              console.log(data);
              this.loading = false;
              this.httpError = false;
               this.getChannelBySid = data.channelSid;
               RegisterComponent.storeItems(data).then(() => {
                   this.router.navigateByUrl('chat');
               });
          }, error => {
              this.errorMessage = error.error.message;
              this.loading = false;
              this.httpError = true;
          });
      } else
          this.validateEmail = false;
  }

  changePasswordSubmit(newPassword, confirmPassword,username){
          if(newPassword !== confirmPassword){
              this.errorMessage = 'Mot de passe et confirme mot de passe incorrecte!';
              this.httpError = true;
              this.httpSuccess = false;
          }else {
              if (newPassword.length < 6) {
                  this.errorMessage = 'Mot de passe doit avoir au moins 6 caractére!'
              } else
                  this.loading = true;
              this.httpService.post({
                  email: this.email,
                  username: username,
                  password: newPassword
              }, 'update-password').subscribe(data => {
                      this.loading = false;
                      this.httpError = false;
                      this.httpSuccess = true;
                      this.successMessage = 'Mot de passe changer avec succés';
                      this.createPassword = false;
                      this.welcome = true;
                      this.changePassword = false;
              }, error => {
                  this.errorMessage = error.error.message;
                  this.loading = false;
                  this.httpError = true;
              });
          }
  }

  renewPassword(){
      this.hasPassword = false;
      this.welcome = false;
      this.forgetPassword = true;
      this.changePassword = true;
  }

  newAccount(){
      this.welcome = false;
      this.createAccount = true;
  }

  backToLogin(){
      this.createAccount = false;
      this.welcome = true;
  }

  backToWelcome(){
      this.forgetPassword = false;
      this.welcome = true;
  }

  togglePassword() {
      this.showPassword = !this.showPassword;
  }

  checkCode(code){
      this.loading = true;
      let suffix = this.changePassword?'recovery/code/verification':'verify-code';

      if(code != ''){
          this.httpService.post({email:this.email,code:code},suffix).subscribe(data => {
              this.httpError = false;
              this.loading = false;
              if(this.changePassword){
                  this.pseudoName = data.user.name;
                  this.successMessage = data.message;
                  this.createPassword = true;
                  this.email = data.user.email;
                  this.verifyCode = false;
                  this.startChat = 'Changer le mot de passe';
              }else{
                  if(data.success){
                      this.getChannelBySid = data.user.channelSid;
                      RegisterComponent.storeItems({token:data.user.token,channelSid:data.user.channelSid, identity:data.user.email, name:data.user.name}).then(() =>{
                          this.router.navigateByUrl('chat');
                      });
                  }else{
                      this.httpError = true;
                      this.errorMessage = data.message;
                  }
              }
          }, error => {
              this.errorMessage = error.error.message;
              this.loading = false;
              this.httpError = true;
          });
      }
  }

  sendVerificationCode(email){
      if(RegisterComponent.emailValidation(email)){
          this.loading = true;
          this.httpService.post({email:email},'send/verification/code').subscribe(data => {
                  this.loading = false;
                  this.httpError = false;
                  this.successMessage = data.message;
                  this.changePassword = true;
                  this.forgetPassword = false;
                  this.email = data.user.email;
                  this.pseudoName = data.user.name;
                  this.httpSuccess = true;
                  this.verifyCode = true;
          }, data => {
              this.errorMessage =data.error.message;
              this.httpSuccess = true;
              this.loading = false;
              this.httpError = true;
          });
      } else
          this.validateEmail = false;
  }
  // @ts-ignore
  static async storeItems(data: Chat){
      localStorage.setItem('agaToken',data.token);
      localStorage.setItem('channelSid',data.channelSid);
      localStorage.setItem('agaIdentity',data.identity);
      return await true;
  }

  static async removeItems(){
      localStorage.removeItem('agaToken');
      localStorage.removeItem('channelSid');
      localStorage.removeItem('agaIdentity');
      return await true;
  }

  static emailValidation(email) {
      const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // @ts-ignore
      return regularExpression.test(String(email).toLowerCase());
  }

  static logout(){
      RegisterComponent.removeItems().then(()=>{
          // @ts-ignore
          this.router.navigateByUrl('/');
      });
  }
}
