import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import {tap, catchError, map, retry} from 'rxjs/operators';
import {EnvService} from "./env.service";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

   headers = new HttpHeaders()
       .set( 'Access-Control-Allow-Origin','all')
       .set('Content-Type', 'application/json')
       .set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS')
       //.set('mode', 'cors')
       .set('Accept', 'application/json');

  public httpOptions = {
    headers: this.headers,
  };

  constructor(private http: HttpClient, private envService: EnvService) {
  }

    // Handling Errors
    private handleError(error: any) {
        return throwError(error);
    }

    // Post Method Api
    post(data: any, suffix): Observable<any> {
        return this.http.post<any>(this.envService.API_URL+suffix, data, this.httpOptions).pipe(
            tap((data) => {
                return data;
            }),
            catchError(this.handleError)
        );
    }

    get(suffix): Observable<any> {
        return this.http.get<any>(this.envService.API_URL+ suffix, this.httpOptions).pipe(
            retry(2),
            tap((data) => {
                        return data;
            }),
            catchError(this.handleError)
        );
    }
}
