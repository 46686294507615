import { Component, Input} from '@angular/core';
import {PwaService} from "../../services/pwa.service";

@Component({
  selector: 'app-install-app',
  templateUrl: './install-app.component.html',
  styleUrls: ['./install-app.component.css']
})
export class InstallAppComponent {

    public loading = false;
    public showInstall: any = PwaService.showInstaller;
  constructor() {
      console.log('installer component');
      console.log(this.showInstall + ' show install state');
      console.log(PwaService.promptEvent+ ' generated event');
  }

  installApp(event){
    // Show the prompt
    if(PwaService.promptEvent){
        this.loading = true;
        event.prompt();
        // Wait for the user to respond to the prompt
        event.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
               // console.log('User accepted the A2HS prompt');
            } else {
               // console.log('User dismissed the A2HS prompt');
            }
            event = null;
        });
    }
}

  cancelApp(){
      $('#install-app').hide();
  }

}
