import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ChatComponent} from './components/chat/chat.component';
import {HomeComponent} from "./components/home/home.component";
import {VerifyCodeComponent} from "./components/verify-code/verify-code.component";
import {CreatePasswordComponent} from "./components/create-password/create-password.component";
import {TestComponent} from "./components/test/test.component";
import {RegisterComponent} from "./components/register/register.component";
import {IntroductionComponent} from "./components/introduction/introduction.component";
import {ChatBotComponent} from "./components/chat-bot/chat-bot.component";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'verify-code', component: VerifyCodeComponent },
  { path: 'password', component: CreatePasswordComponent },
  { path: 'test', component: TestComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'introduction', component: IntroductionComponent },
  { path: 'chat-bot', component: ChatBotComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
