import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { ChatComponent } from './components/chat/chat.component';
import { HomeComponent } from './components/home/home.component';
import { VerifyCodeComponent } from './components/verify-code/verify-code.component';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { TestComponent } from './components/test/test.component';
import { RegisterComponent } from './components/register/register.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { PickerModule } from '@ctrl/ngx-emoji-mart'
import {AngularFireModule} from "@angular/fire";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {EnvService} from "./services/env.service";
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgxSpinnerModule } from "ngx-spinner";
import {PwaService} from "./services/pwa.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InstallAppComponent } from './components/install-app/install-app.component';
import { IntroductionComponent } from './components/introduction/introduction.component';
import { ChatBotComponent } from './components/chat-bot/chat-bot.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    HomeComponent,
    VerifyCodeComponent,
    CreatePasswordComponent,
    TestComponent,
    RegisterComponent,
    SpinnerComponent,
    InstallAppComponent,
    IntroductionComponent,
    ChatBotComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AngularFireModule.initializeApp(EnvService.fireBaseConfig),
    AngularFireMessagingModule,
    FormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    PickerModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ModalModule.forRoot()
  ],
  providers: [PwaService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
