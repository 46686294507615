import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Channel} from 'twilio-chat/lib/channel';
import {Message} from 'twilio-chat/lib/message';
import {ChatService} from '../../services/chat.service';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {HomeComponent} from '../home/home.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {DiagnosticService} from '../../services/diagnostic.service';
import {Diagnostics} from '../../interface/diagnostics';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.css']
})
export class ChatBotComponent implements OnInit, AfterViewInit {

  public isConnected = false;
  public channels: any[] = [];
  public getChannelBySid;
  public newToken;
  public newChannelSid;
  public newIdentity;
  public getToken;
  public chatMessage: string;
  public otherObjective: string;
  public ladder: string;
  public isEmojiPickerVisible: boolean;
  public messages: Message[] = this.chatService.messages;
  public objectifs: Array<string> = [];
  public disableSendMessage = true;
  public displayName: string = localStorage.getItem('displayName');
  public identity: string = localStorage.getItem('agaIdentity');
  public diagnosticsOk: Array<Diagnostics> = [];
  public diagnosticsInProgress: Array<Diagnostics> = [];
  public allDiagnostics: Array<Diagnostics>;
  public currentDiagnostic: Diagnostics;
  public step: any;
  public loading = false;
  public httpError = false;
  public errorMessage: string;
  public formOk = false;
  public isEmpty = false;
  public newObjectiveOk = false;
  public newObjectiveValue = '';

  @ViewChild('chatElement') chatElement: any;
  @ViewChild('chatDisplay') chatDisplay: any;

  constructor(public chatService: ChatService,
              private router: Router,
              private httpService: HttpService,
              public diagnosticsService: DiagnosticService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.displayName = localStorage.getItem('displayName');
    this.homePage();
    // console.log(this.diagnostics[this.step].question.label, this.step);
    /*let data = {token:this.chatToken, identity:this.currentUsername, channelSid: this.currentChannelSid};
    this.chatService.connect(data).then(next => {
      //$('html,body').animate({ scrollTop: 9999 }, 'slow');
      $(".messages").last()[0].scrollIntoView();
      /!*console.log('le total des messages est '+this.chatService.messages.length);*!/
    });
    //console.log($(".messages").last()[0]);
    $(".messages").last()[0].scrollIntoView();*/
  }


  ngAfterViewInit() {
    /*this.currentChannel = this.chatService.currentChannel;
    $(".messages").last()[0].scrollIntoView();*/
    // $('html, body').animate({scrollTop:$('#for_scroll').position().top}, 'slow');
  }

  start() {
    let sid = localStorage.getItem('channelSid');

    if(JSON.stringify(sid) ==='null' || sid === 'null' ) {
      this.allDiagnostics = JSON.parse(localStorage.getItem('diagnostics'));
      this.step = localStorage.getItem('step');
      if (!this.step) {
        this.step = 0;
        this.allDiagnostics = this.diagnosticsService.diagnostics;
      }
      if (parseInt(this.step) > 0) {
        for (let i = 0; i < parseInt(this.step); i++) {
          this.diagnosticsOk.push(this.allDiagnostics[i]);
        }
        // this.diagnosticsInProgress.splice(parseInt(this.step), this.allDiagnostics.length);
      }

      if (parseInt(this.step) < this.allDiagnostics.length) {
        this.currentDiagnostic = this.allDiagnostics[parseInt(this.step)];
      } else {
        HomeComponent.storeItems({
          token: this.newToken,
          channelSid: this.newChannelSid,
          identity: this.newIdentity,
          name: this.displayName
        }).then(() => {
          this.router.navigateByUrl('chat');
        });
      }
    }
      else {
        this.router.navigateByUrl('chat');
      }
          /*console.log('Ok', this.step, this.diagnosticsOk);
          console.log('in progress', this.step, this.diagnosticsInProgress);
          console.log('All', this.step, this.allDiagnostics);*/
    // if(this.getToken && this.getChannelBySid &&  this.getIdentity  ){
      // this.router.navigateByUrl('chat');
    // }
  }

  async homePage(){
    await this.start();
    await this.spinner.hide();
  }


  public enableSubmit() {
    const text = '' + $('#chatMessage').val(),
        out = text.replace(/\s/g, '');
    if (out.length > 0) {
      this.formOk = true;
      this.chatMessage = text;
    }
    else {
      this.formOk = false;
    }
  }
  sendMessage() {
    this.step = this.diagnosticsService.step;
    this.allDiagnostics[parseInt(this.step)].response = this.chatMessage;
      this.diagnosticsService.storeDiagnostics(this.step, this.chatMessage);
    this.diagnosticsOk.push(this.allDiagnostics[parseInt(this.step)]);
    this.step = this.diagnosticsService.step;
    this.chatMessage = null;
    this.formOk = false;
    $('#chatMessage').val('');
    if (this.step < this.allDiagnostics.length) {
      this.currentDiagnostic = this.allDiagnostics[parseInt(this.step)];
    }
    else{
      this.currentDiagnostic = null;
      this.loading = true;
      this.httpService.post({email: this.identity, diagnostics: JSON.stringify(this.diagnosticsOk)}, 'emailLogin').subscribe(data => {
        this.loading = false;
        this.httpError = false;
        if (data.success){
          this.getChannelBySid = data.channelSid;
          localStorage.setItem('firstMessage', 'ok');
          HomeComponent.storeItems(data).then(() =>{
            this.router.navigateByUrl('chat');
          });
        }else{
          this.httpError = true;
          this.errorMessage = data;
        }
      }, error => {
        console.log(error);
        this.errorMessage = error.error.message;
        this.loading = false;
        this.httpError = true;
      });
    }

    /*this.chatService.currentChannel.sendMessage(this.chatMessage).then(()=>{
      this.chatMessage = null;
      const el = this.chatDisplay.nativeElement;
      this.formOk=false;
      $('#chatMessage').val('');
      //$('#for_scroll').animate({scrollTop:0}, '500', 'swing');
      //$('html, body').animate({scrollTop:$('#for_scroll').position().top}, 'slow');
      $(".messages").last()[0].scrollIntoView();

    });*/

  }

  public addEmoji(event) {
    if (this.chatMessage !== undefined) {
      this.chatMessage = `${this.chatMessage}${event.emoji.native}`;
    }
    else{
      this.chatMessage = `${event.emoji.native}`;
      this.formOk = true;
    }

  }

  public trackByIndex(index, item) {
    if (index === 29){
      this.loading = false;
    }
    return index; // or item.id
  }

  public isLast(number){
    this.loading = true;
    /* console.log('loading = '+this.loading);*/
    if (number === this.chatService.messages.length - 1){
      this.loading = false;
    }
  }
  public setLadder(value){
    const stage1 = $('#option0');
    const stage2 = $('#option1');
    const stage3 = $('#option2');
    const stage4 = $('#option3');
    const stage5 = $('#option4');

    if (value == '1. Triste')
    {
        if ($(stage1).is(':not(:checked)'))
        {
            console.log('checked');
        } else {
            $('.animal').html('🙁');
        }
    }
    if (value == '2. Pas bien')
    {
        if ($(stage2).is(':not(:checked)'))
        {
            console.log('checked2');
        } else {
            $('.animal').html('😩');
        }
    }
    if (value == '3. Cool')
    {
        if ($(stage3).is(':not(:checked)'))
        {
            console.log('checked2');
        } else {
            $('.animal').html('🙂');
        }
    }
    if (value == '4. Bien')
    {
        if ($(stage4).is(':not(:checked)'))
        {
            console.log('checked2');
        } else {
            $('.animal').html('😀');
        }
    }
    if (value == '5. Super')
    {
        if ($(stage5).is(':not(:checked)'))
        {
            console.log('checked2');
        } else {
            $('.animal').html('🤩');
        }
    }


    this.ladder = value;
    $('#chatMessage').val(this.ladder);
    this.chatMessage = this.ladder;
    this.formOk = true;
  }

  public setObejectifs(value){
    const feedback: Array<string> = this.objectifs.filter(result =>
        result === value
    );
    const obj1 = $('#option0');
    const obj2 = $('#option1');
    const obj3 = $('#option2');
    const obj4 = $('#option3');
    const obj5 = $('#option4');
    const obj6 = $('#option5');

    if (feedback.length == 0){
      if(value !== 'Autre')
        this.objectifs.push(value);
    }else{
      this.objectifs = this.objectifs.filter(function(result) {
        return result !== value;
      });
    }
    console.log(feedback);
    console.log(this.objectifs);
    console.log(this.objectifs.join(', '));
    $('#chatMessage').val(this.objectifs.join(', '));
    if (value == 'Se motiver pour un projet'){
      if ($(obj1).is(':not(:checked)')) {
        $('#option0').addClass('d-none');
        $('.objective0').css('opacity', '1');
      } else {
        $('.objective0').css('opacity', '0.5');
        $('#option0').removeClass('d-none');
      }
    }

    if (value == 'Me sentir moins anxieux'){
      if ($(obj2).is(':not(:checked)')) {
        $('#option1').addClass('d-none');
        $('.objective1').css('opacity', '1');
      } else {
        $('.objective1').css('opacity', '0.5');
        $('#option1').removeClass('d-none');
      }
    }
    if (value == 'Réduire mon stress'){
      if ($(obj3).is(':not(:checked)')) {
        $('#option2').addClass('d-none');
        $('.objective2').css('opacity', '1');
      } else {
        $('.objective2').css('opacity', '0.5');
        $('#option2').removeClass('d-none');
      }
    }
    if (value == 'Gérer ma solitude'){
      if ($(obj4).is(':not(:checked)')) {
        $('#option3').addClass('d-none');
        $('.objective3').css('opacity', '1');
      } else {
        $('.objective3').css('opacity', '0.5');
        $('#option3').removeClass('d-none');
      }
    }
    if (value == 'Etre plus positve'){
      if ($(obj5).is(':not(:checked)')) {
        $('#option4').addClass('d-none');
        $('.objective4').css('opacity', '1');
      } else {
        $('.objective4').css('opacity', '0.5');
        $('#option4').removeClass('d-none');
      }
    }
    if (value == 'Autre'){
      if ($(obj6).is(':not(:checked)')) {
        console.log("not checked");
          $('#exampleModal').show();
          $('#modal_shadow').css("display", "block");
          $('.objective5').css('opacity', '0.5');
      } else {
          $('#exampleModal').show();
          $('#modal_shadow').css("display", "block");
          $('.objective5').css('opacity', '0.5');
          $('#option5').removeClass('d-none');


      }
    }
    this.chatMessage = this.objectifs.join(', ');
    if (this.objectifs.length > 0) {
      this.formOk = true;
    }
    else {
      this.formOk = false;
    }
  }

  public isNewConversattion(messages){
    if (messages.length > 0) {
      this.loading = true;
    }
    else {
      this.loading = false;
    }
    console.log('loading =  ' + this.loading);
  }

  logout(){
    HomeComponent.logout();
  }

  showImage(message){
    // onclick="showImage(message)"
    return  message.media.getContentTemporaryUrl()[Symbol.toStringTag];
  }
  
  // modale pour ajouter un objectif

   /* openModal(){
        $('#exampleModal').show();
        $('#modal_shadow').css("display", "block");
    }*/
    closeModal(){
        $('#exampleModal').hide();
        $('#modal_shadow').css("display", "none");
    }
    newObjective(){
      let obj = ''+$('#other-objective').val();
      if(obj == '') {
          this.isEmpty = true;
          //$('#option5').addClass('d-none');
      }
      else {
          this.otherObjective = obj;
          this.currentDiagnostic.question.options[5] = obj;
          this.diagnosticsService.storeOther(obj);
          console.log('Test',this.diagnosticsService);
          this.closeModal();
          this.newObjectiveValue = obj;
          this.newObjectiveOk = true;
          this.objectifs.push(obj);
          $('#chatMessage').val(this.objectifs.join(', '));
          this.chatMessage = this.objectifs.join(', ');
      }
    }


}
