<div class="main">

    <!-- ***** Header End ***** -->

    <!-- ***** Welcome Area Start ***** -->
    <section id="home" class="section welcome-area bg-overlay d-flex align-items-center overflow-hidden">
        <!-- <div class="container mx-auto" *ngIf="!isLoaded" style="margin-bottom: 110px;">
            <app-spinner></app-spinner>
        </div> -->
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#49247e" type="square-jelly-box" [fullScreen]="true">
            <p style="color: white"> Chargement... </p>
        </ngx-spinner>

        <div class="container mx-auto" style="margin-bottom: 110px;">
            <div class="row align-items-center containc">
                <div class="col-12 col-md-12 col-lg-12">
                    <div class="welcome-intro">
                        <img class="navbar-brand-regular" src="/assets/img/logo-aga.png" alt="brand-logo">
                    </div>
                </div>
                <div class="col-md-12 col-12 col-lg-12 " style="text-align: center;">
                    <div class="notification notification-success" *ngIf="httpSuccess" style="text-align: center;">
                        {{successMessage}}
                    </div>
                    <div class="notification notification-error" *ngIf="httpError" style="text-align: center;">
                        {{errorMessage}}
                    </div>
                </div>
                <!-- Intro Start -->
                <div *ngIf="introduction" class="welcome animate__animated animate__slideInRight mx-auto" id="prehome">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro text-left" style="font-size: 20px; padding-left: 20px;">
                            <br><br>

                            <h2 class="h2-intro">Bienvenue sur Aga, {{userName}}</h2><br>

                            <span style="font-size: 20px;">
                                Agacare est un service de bien-être et d’écoute conversationnelle avec​
                            </span>
                            <ul>
                                <li>Du chat –texte avec réponse garantie 24h/24​</li>
                                <li>Des exercices bien-être pour aller mieux et s’élever au quotidien​</li>
                                <li>Des vidéos avec des experts de la santé mentale (si besoin)</li>
                            </ul>
                        </div>
                    </div>

                    <div class="subscribe-form align-items-center text-center">
                        <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                        <button type="button" [attr.disabled]="loading?true:null" (click)="goToStep1()" class="btn-bordered">
                            <span [ngClass]="loading?'loading':''"></span> Suivant
                        </button>
                    </div>
                </div>
                <!-- Intro End -->

                <!-- Step 1 start -->
                <div *ngIf="step1" class="welcome animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro text-left" style="font-size: 20px;">
                            <br><br>
                            Afin de mieux personnaliser nos échanges, as tu une idée de ton objectif bien etre?<br>
                            <section style="padding: 40px; margin-left: 80px;">
                                <div class="row" style="text-align: center;">
                                    <div class="col-md-5" style="background-color: #ea8fe5; height: 125px; margin-right: 20px; border-radius: 15px; margin-bottom: 20px;">

                                        <input type="checkbox" name="" class="rightCheckbox" id="depression">
                                        <label for="depression" class="title">Dépression</label>
                                    </div>
                                    <div class="col-md-5" style="background-color: paleturquoise; height: 125px; margin-right: 20px; border-radius: 15px; margin-bottom: 10px;">

                                        <input type="checkbox" name="" class="rightCheckbox" id="anxiete">
                                        <label for="anxiete" class="title">Anxiété</label>
                                    </div>
                                </div>
                                <div class="row" style="text-align:center;">
                                    <div class="col-md-5" style="background-color: #0aa778b3; height: 125px; margin-right: 20px; border-radius: 15px; margin-bottom: 20px;">

                                        <input type="checkbox" name="" class="rightCheckbox" id="confidence">
                                        <label for="confidence" class="title">Confidence</label>
                                    </div>
                                    <div class="col-md-5" style="background-color: #d6d639; height: 125px; margin-right: 10px; border-radius: 15px; margin-bottom: 20px;">
                                        <input type="checkbox" name="" class="rightCheckbox">
                                        <label class="title" for="stress">Stress au boulot</label>
                                    </div>
                                </div>
                                <div class="row" style="text-align:center;">
                                    <div class="col-md-5" style="background-color: #d66e6e; height: 125px; margin-right: 20px; border-radius: 15px; margin-bottom: 20px;">

                                        <input type="checkbox" name="" class="rightCheckbox" id="peur">
                                        <label for="peur" class="title">Peur</label>
                                    </div>
                                    <div class="col-md-5" style="background-color: #2347bbb3; height: 125px; margin-right: 10px; border-radius: 15px; margin-bottom: 20px;">
                                        <input type="checkbox" name="" class="rightCheckbox" id="sommeil">
                                        <label class="title" for="sommeil">Sommeil</label>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <br><br><br>
                    <div class="subscribe-form align-items-center text-center">
                        <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                        <button type="button" [attr.disabled]="loading?true:null" (click)="goToStep2()" class="btn-bordered">
                            <span [ngClass]="loading?'loading':''"></span> Suivant
                        </button>
                    </div>
                </div>
                <!-- Step 1 End -->

            </div>
        </div>
    </section>
    <section class="footer" style="position: fixed;bottom: 0; z-index: 99; background-color: #fff;width: 100%;">
        <!-- Shape Bottom -->
        <div class="row">
            <div class="col-md-12" style="width: 60%; margin: auto; text-align: center;">
                <div>
                    <br>
                    <span style="color: #565656; font-family: Poppins ; text-align: center;">
                        <!-- <sup>*</sup> -->
                        En utilisant Aga, vous acceptez nos conditions d'utilisation.
                    </span>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Welcome Area End ***** -->
</div>
