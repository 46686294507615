import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
@Injectable()
export class PwaService {
  public static promptEvent: any = null;
  public static installedEvent: any = null;
  public static showInstaller: boolean = false;

  constructor(private swUpdate: SwUpdate) {
    swUpdate.available.subscribe(event => {
      if(confirm("Les mis a jour de l'application disponible. Voulez vous installer?")) {
        window.location.reload();
      }
    });

    /*window.addEventListener('beforeinstallprompt', event => {
      event.preventDefault();
      PwaService.promptEvent = event;
      PwaService.showInstaller = true;
    });

    window.addEventListener('appinstalled', event => {
      event.preventDefault();
      PwaService.promptEvent = null;
      PwaService.installedEvent = event;
    });*/
  }
}
