import {Component, OnInit} from '@angular/core';
import {PwaService} from "./services/pwa.service";
import { SwUpdate } from '@angular/service-worker';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public updateAvailable: boolean = false;
  constructor( private swUpdate: SwUpdate) {
    swUpdate.available.subscribe(event => {
      this.updateAvailable = true;
    });
  }

  ngOnInit(): void {

  }

  updateApp(){
  //  if(confirm("Les mis a jour de l'application disponible. Voulez vous installer?")) {  }
      window.location.reload();
    this.updateAvailable = false;
  }
}
