import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Channel} from 'twilio-chat/lib/channel';
import {Message} from 'twilio-chat/lib/message';
import {ChatService} from '../../services/chat.service';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {HomeComponent} from "../home/home.component";
import { NgxSpinnerService } from "ngx-spinner";
import {DiagnosticService} from "../../services/diagnostic.service";
import {Diagnostics} from "../../interface/diagnostics";


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewInit {

  public isConnected = false;
  public isConnecting = false;
  public isGettingChannels = false;
  public channels: any[] = [];
  public channelObj: any;
  public mediaUrl: any = 'https://media.us1.twilio.com/ME3167aff06a97a91f6bd5a0cf8655d8f5?Expires=1617899859&Signature=cx7kS2fv6ZDPMrr~cWW3wfdsSb7XEfbaMivvfEVeCXmuzLibbrr8BfJBCRamdRJq1ZrVpYsNWCkcIU7aiCgQXnO~XMBDCDzje9MpsJ9rLLA004vRNUUGPXtIYek4dBIzHZLSJ1HG2bUHp394nFM4Uy8Sfgwu3Z5-bsiih326WS0DanKDbtiesOVKngYk8aluQL9BSUhVurbwYuYos9DE8IAkHLbSMumeu~kIk25zYYHVtuctEgm-ULpNXV6ITu8Tm9EI0zYUdINq0HgHQfenz3Qb10Y7wPmHUKKHGG8c1If6tqaWX3XAuYn-AsyiqRmqo-thZLsSZtsMoh7kJpXUfA__&Key-Pair-Id=APKAJWF6YVTMIIYOF3AA';
  public chatMessage: string;
  public isEmojiPickerVisible: boolean;
  public currentChannel: Channel = this.chatService.currentChannel;
  public messages: Message[] = this.chatService.messages;
  public currentUsername: string = localStorage.getItem('agaIdentity');
  public currentChannelSid: string = localStorage.getItem('channelSid');
  public chatToken: string = localStorage.getItem('agaToken');
  public diagnostic = true;
  public question1 = true;
  public anwser1 = false;
  public question2 = false;
  public anwser2 = false;
  public objectifs: Array<string> = [];
  public disableSendMessage: boolean = false;
  public ladder: string = '';
  public displayName: string = localStorage.getItem('displayName');
  public isMemberOfCurrentChannel = false;
  public membersTyping: any = [];
  public messagesLength = this.chatService.messages.length;
  public loading = false;
  private conSub: any;
  private disconSub: any;
  public formOk=false;
  public allDiagnostics: Array<Diagnostics>;

  @ViewChild('chatElement') chatElement: any;
  @ViewChild('chatDisplay') chatDisplay: any;
  @ViewChild('chatForm') testFormElement;

  constructor(public chatService: ChatService,
              private router: Router,
              public diagnosticsService: DiagnosticService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    //this.displayName = localStorage.getItem('displayName');
    let data = {token:this.chatToken, identity:this.currentUsername, channelSid: this.currentChannelSid, name: this.displayName};
    let firstMessage = localStorage.getItem('firstMessage');
    this.allDiagnostics = JSON.parse(localStorage.getItem('diagnostics'));
    this.chatService.connect(data,this.allDiagnostics,firstMessage).then(next => {
      //$('html,body').animate({ scrollTop: 9999 }, 'slow');
      this.currentChannel = this.chatService.currentChannel;
      //$(".messages").last()[0].scrollIntoView();

      console.log('firstMessage = ', firstMessage);

    }).catch(error=>{
      console.log(error);
    });
  }

  ngAfterViewInit() {


    //$('html, body').animate({scrollTop:$('#for_scroll').position().top}, 'slow');

  }


  public enableSubmit() {
    const text = '' + $('#chatMessage').val(),
        out = text.replace(/\s/g, '');
    if (out.length > 0) {
      this.formOk = true;
      this.chatMessage = text;
    }
    else {
      this.formOk = false;
    }
  }
  sendMessage() {
    console.log( this.chatService.currentChannel);
    this.chatService.currentChannel.sendMessage(this.chatMessage).then(()=>{
      this.chatMessage = null;
      const el = this.chatDisplay.nativeElement;
      this.formOk = false;
      $('#chatMessage').val('');
      // $('#for_scroll').animate({scrollTop:0}, '500', 'swing');
      // $('html, body').animate({scrollTop:$('#for_scroll').position().top}, 'slow');
      $('.messages').last()[0].scrollIntoView();

      if (this.question1){
          this.question2 = true;
          this.question1 = false;
      }
      else if (this.question2){
            this.question2 = false;
      }

    });

  }

  public addEmoji(event) {
    if (this.chatMessage !== undefined) {
      this.chatMessage = `${this.chatMessage}${event.emoji.native}`;
    }
    else{
      this.chatMessage = `${event.emoji.native}`;
      this.formOk = true;
    }

  }

  public trackByIndex(index, item) {
    if (index === 29){
      this.loading = false;
    }
    return index; // or item.id
  }

  public isLast(number){
    this.loading = true;
    /* console.log('loading = '+this.loading);*/
    if (number === this.chatService.messages.length - 1){
      this.loading = false;
    }
  }

  public setLadder(value){
    this.ladder = 'Etat : ' + value;
    $('#chatMessage').val(this.ladder);
    this.chatMessage = this.ladder;
    this.formOk = true;
  }

  public setObejectifs(value){
    const feedback: Array<string> = this.objectifs.filter(result =>
        result === value
    );
    const checkedInput = $('.boxes input[type="checkbox"]');
    const obj1 = $('#depression2');
    const obj2 = $('#anxieux');
    const obj3 = $('#stress');
    const obj4 = $('#solitude');
    const obj5 = $('#joie');
    const obj6 = $('#autre');


    if (feedback.length == 0){
      this.objectifs.push(value);
    }else{
      this.objectifs = this.objectifs.filter(function(result) {
        return result !== value;
      });
    }

    if (value == 'Dépression'){
        if ($(obj1).is(':not(:checked)')) {
            $('#depression2').addClass('d-none');
            $('.objective1').css('opacity', '1');
        } else {
            $('.objective1').css('opacity', '0.5');
            $('#depression2').removeClass('d-none');
        }
    }

    if (value == 'Anxieux'){
        if ($(obj2).is(':not(:checked)')) {
            $('#anxieux').addClass('d-none');
            $('.objective2').css('opacity', '1');
        } else {
            $('.objective2').css('opacity', '0.5');
            $('#anxieux').removeClass('d-none');
        }
    }
    if (value == 'Stress'){
        if ($(obj3).is(':not(:checked)')) {
            $('#stress').addClass('d-none');
            $('.objective3').css('opacity', '1');
        } else {
            $('.objective3').css('opacity', '0.5');
            $('#stress').removeClass('d-none');
        }
    }
    if (value == 'Solitude'){
        if ($(obj4).is(':not(:checked)')) {
            $('#solitude').addClass('d-none');
            $('.objective4').css('opacity', '1');
        } else {
            $('.objective4').css('opacity', '0.5');
            $('#solitude').removeClass('d-none');
        }
    }
    if (value == 'Joie'){
        if ($(obj5).is(':not(:checked)')) {
            $('#joie').addClass('d-none');
            $('.objective5').css('opacity', '1');
        } else {
            $('.objective5').css('opacity', '0.5');
            $('#joie').removeClass('d-none');
        }
    }
    if (value == 'Autre'){
        if ($(obj6).is(':not(:checked)')) {
            $('#autre').addClass('d-none');
            $('.objective6').css('opacity', '1');
        } else {
            $('.objective6').css('opacity', '0.5');
            $('#autre').removeClass('d-none');
        }
    }



    console.log(feedback);
    console.log(this.objectifs);
    console.log(this.objectifs.join(', '));
    $('#chatMessage').val(this.objectifs.join(', '));

    this.chatMessage = this.objectifs.join(', ');
    if (this.objectifs.length > 0) {
      this.formOk = true;
    }
    else {
      this.formOk = false;
    }
  }

  public isNewConversattion(messages){
    if (messages.length > 0) {
      this.loading = true;
    }
    else {
      this.loading = false;
    }
    console.log('loading =  ' + this.loading);
  }

  logout(){
    HomeComponent.logout();
  }

  showImage(message){
   // onclick="showImage(message)"
    return  message.media.getContentTemporaryUrl()[Symbol.toStringTag];
  }

  // ladder styles

}
