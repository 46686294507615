import {EventEmitter, Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Channel} from "twilio-chat/lib/channel";
import Client from "twilio-chat";
import * as Twilio from 'twilio-chat';
import {Util} from '../util/util';
import {Chat} from "../interface/chat";
import {Message} from "twilio-chat/lib/message";
import * as $ from "jquery";
import * as firebase from 'firebase';
import {AngularFireMessaging,} from "@angular/fire/messaging";
import {Diagnostics} from "../interface/diagnostics";

@Injectable({
  providedIn: 'root'
})

export class ChatService {

  public chatClient: Client;
  public messageLoader = false;
  public chatName: String;
  public currentChannel: Channel;
  public chatConnectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  public chatDisconnectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  public messages: Message[] = [];
  public messageLength = 0;
 public history = [];
 public mediaUrl:string = '';
  constructor(
      private router: Router,
      private angularFireMessaging:AngularFireMessaging
  ) {

  }

  async  connect(data: Chat,diagnostics:Array<Diagnostics> = [],firstMessage=null) {
    Twilio.Client.create(data.token).then( (client: Client) => {
      this.chatClient = client;
      this.chatName = data.identity;
        this.angularFireMessaging.requestToken.subscribe((token) => {
           /* console.log(token);
            console.log('registration ...');*/
            this.chatClient.setPushRegistrationId('fcm', token).then(()=>{
                //console.log('registered');
            });

            this.angularFireMessaging.onMessage((function(payload) {
               // console.log('message ..... ', payload);
            }));
        },(err) => {
            console.log(err);
        });
      this.chatClient.getSubscribedChannels().then(()=>{
          console.log(diagnostics,firstMessage);
        this.createOrJoinGeneralChannel(data.channelSid,this.chatName = '',diagnostics,firstMessage).then(done =>{
          this.chatConnectedEmitter.emit(true);
        });
      });  //return await true;
    }).catch( (err: any) => {
      this.chatDisconnectedEmitter.emit(true);

      if( err.message.indexOf('token is expired') ) {
        localStorage.removeItem('agaToken');
        this.router.navigateByUrl('');
      }
    });
    return await true;
  }

  getPublicChannels() {
    return this.chatClient.getPublicChannelDescriptors();
  }

  getChannel(sid: string) {
    return this.chatClient.getChannelBySid(sid);
  }

  createChannel(friendlyName: string, isPrivate: boolean=false) {
    return this.chatClient.createChannel({friendlyName: friendlyName, isPrivate: isPrivate, uniqueName: Util.guid()});
  }

  async createOrJoinGeneralChannel(channelName,friendlyName:string = '',diagnostics:Array<Diagnostics> = [],firstMessage=null) {
    // Get the general chat channel, which is where all the messages are
   //console.log('Attempting to join chat channel...');
    this.chatClient.getChannelBySid(channelName).then(channel => {
         //console.log('Found channel : ' +channel);
          this.currentChannel = channel;
          this.messageLoader = true;
            channel.getMessages(1000).then(messages => {
                this.messages = messages.items;
                this.messageLoader = false;
                this.messageLength = this.messages.length;
            });
        localStorage.removeItem('firstMessage');
        localStorage.removeItem('step');
        localStorage.removeItem('diagnostics');
          /*this.initDialogue(channel, firstMessage, diagnostics).then(()=>{
              localStorage.removeItem('firstMessage');
              localStorage.removeItem('step');
              localStorage.removeItem('diagnostics');
              console.log('dedans le then');
          });*/
          this.setupChannel();
        }).catch(reject => {
          // If it doesn't exist, let's create it
         //console.log('Channel not found');
          this.router.navigateByUrl('/');
    });
    return await true;
  }

    async initDialogue(channel, firstMessage, diagnostics){
      console.log('dedans initDialogue');
        if(firstMessage=='ok'){
            let chatMessage = '';
            let allDiagnostics:Array<Diagnostics> = diagnostics;
            for(let i=0; i<allDiagnostics.length; i++){
                chatMessage = '';
                chatMessage += allDiagnostics[i].question.label+'//';
                chatMessage += allDiagnostics[i].response;
                channel.sendMessage(chatMessage).then(()=>{

                });
            }
            channel.getMessages(1000).then(messages => {
                this.messages = messages.items;
                this.messageLoader = false;
                this.messageLength = this.messages.length;
            });
        }else{
            channel.getMessages(1000).then(messages => {
                this.messages = messages.items;
                this.messageLoader = false;
                this.messageLength = this.messages.length;
            });
        }
    }

  setupChannel(): void {
    // Join the general channel
    this.currentChannel.join().then(channel => {
     //console.log('Joined channel as ' + this.chatName);
      });
    this.currentChannel.on('messageAdded', (message) => {
        console.log(message);
        /*if (message.type === 'media') {
             message.media.getContentTemporaryUrl().then(function(url) {
                // log media temporary URL
                 console.log(url);
                 //this.mediaUrl  =  url;
            });
        }*/
      this.messages.push(message);
        $('html, body').animate({scrollTop:$('#for_scroll').position().top}, 'slow');
    });
  }
}
