import { Injectable } from '@angular/core';
import {toArray} from "rxjs/operators";
import {Diagnostics} from "../interface/diagnostics";

@Injectable({
  providedIn: 'root'
})
export class DiagnosticService {
  public step = 0;
  public diagnostics: Array<Diagnostics> = [
    {
      question: {
        label : 'Pour personnaliser nos futurs échanges, merci de préciser vos objectifs bien-être​',
        options: [
          'Se motiver pour un projet',
          'Me sentir moins anxieux',
          'Réduire mon stress',
          'Gérer ma solitude',
          'Etre plus positve',
          'Autre',
        ]
      },
      response: null,
      styleType: 'checkbox'
    },
    {
      question: {
        label : 'Peux-tu me dire sur une échelle de 1 à 5 comment tu te sens là tout de suite?',
        options: [
          '1. Triste',
          '2. Pas bien',
          '3. Cool',
          '4. Bien',
          '5. Super',
        ]
      },
      response:null,
      styleType: 'radio'
    }];
  constructor() {
    this.get();
  }

  get(){
    let step = localStorage.getItem('step');
    if(step !== undefined && parseInt(step) > 1){
      this.step = parseInt(step);
      this.diagnostics = JSON.parse(localStorage.getItem('diagnostics'));
      console.log('dedannnnnnnnnnnnnnnns');
      return this.diagnostics;
    }
    else{
        localStorage.setItem('diagnostics', JSON.stringify(this.diagnostics));
        localStorage.setItem('step', JSON.stringify(this.step));
        return this.diagnostics;
    }
  }

  storeOther(other) {
    this.diagnostics[0].question.options[5] = other;
  }

  storeDiagnostics(step, response){
    this.diagnostics[step].response = response;
    if(step<this.diagnostics.length)
      this.step = step + 1;
    localStorage.setItem('diagnostics', JSON.stringify(this.diagnostics));
    localStorage.setItem('step', JSON.stringify(this.step));
  }
}
