<section id="install-app" style="display: none; position: fixed;bottom: 0; z-index: 999; background-color: rgba(143, 138, 138, 0.493);width: 100%;height: 100%;">
    <div class="row" style="position: absolute;
        bottom: 15px;
        left: 5%;
        width: 90%;
        background-color: #fff;
        min-height: 60px;">
        <div class="col-md-12 px-4 py-4">
            <h4>Installer l'application ?</h4>
            <div class="row">
                <div class="col-md-12">
                    <img class="" src="/assets/img/favicon.png" alt="brand-logo" style="width: 100px; float: left;margin-right: 10px;">
                    <span style="display: block;margin-top: 15px;">
                            En installant cette application, vous bénéficierez d'une meilleure expérience utilisateur.
                        </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-right mt-3">
                    <button type="button" (click)="cancelApp()" class="btn-bordered px-3" style="height: 40px;
                        border-radius: 8px;
                        background: #fff !important;
                        border: 1px solid #49247e;
                        padding: 4px 4px;
                        font-size: 16px;
                        line-height: 20px;
                        min-width: 130px;
                        font-weight: 400;
                        color: #49247e;">
                            Annuler
                        </button> &nbsp;&nbsp;
                  <!--  [attr.disabled]="loading?true:null"-->
                    <button type="button"  class="btn-bordered px-3 install-btn" style="position: relative;
                        height: 40px;
                        border-radius: 8px;
                        background: #49247e !important;
                        border: 1px solid #49247e;
                        padding: 4px 4px;
                        font-size: 16px;
                        min-width: 130px;
                        line-height: 20px;
                        font-weight: 400;
                        color: #fff;">
                            <span class="loading install-spinner" style="display: none;"></span> Installer
                        </button>
                </div>
            </div>
        </div>
    </div>
</section>
