<!-- Main Wrapper -->
<div class="main-wrapper">
    <!-- content -->
    <div class="content main_content" id="chatDisplay">
        <!-- sidebar group -->
        <!--<div class="sidebar-group left-sidebar chat_sidebar">
            &lt;!&ndash; Chats sidebar &ndash;&gt;
            <div id="chats" class="left-sidebar-wrap sidebar active">
                &lt;!&ndash;<div class="header">
                    <div class="header-top">
                        <div class="logo ml-2 mt-3">
                            <a href="index.html">
                                <img src="assets/img/logo.png" class="header_image img-fluid" alt="">
                            </a>
                        </div>
                        <ul class="header-action mt-4">
                            <li>
                                <a href="#" data-toggle="dropdown">
                                    <i class="fas fa-ellipsis-h ellipse_header"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right header_drop_icon">
                                    <a class="dropdown-item" data-toggle="modal" data-target="#new_group">
                                        New Group
                                    </a>
                                    <a class="dropdown-item" data-toggle="modal" data-target="#profile_modal">Profile</a>
                                    <a class="dropdown-item" data-toggle="modal"
                                        data-target="#settings_modal">Settings</a>
                                    <a href="login.html" class="dropdown-item">Logout</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ul class="nav nav-tabs chat-tabs mt-4">
                        <li class="nav-item">
                            <a class="nav-link  active" href="index.html">Chat</a>
                        </li>
                        <li class="nav-item ml-1">
                            <a class="nav-link" href="group.html">Group</a>
                        </li>
                        <li class="nav-item ml-1">
                            <a class="nav-link" href="status.html">Status</a>
                        </li>
                        <li class="nav-item ml-1">
                            <a class="nav-link" href="call-log.html">Call</a>
                        </li>
                    </ul>
                    <button type="button" class="float-right btn btn-circle btn-sm header_button"
                        data-toggle="modal" data-target="#chat-new">
                        <i class="fas fa-plus button_plus"></i>
                    </button>
                </div>
                <div class="search_chat has-search">
                    <span class="fas fa-search form-control-feedback"></span>
                    <input class="form-control chat_input" id="search-contact" type="text" placeholder="">
                </div>&ndash;&gt;
                <div class="sidebar-body" id="chatsidebar">
                    <ul class="user-list">
                        <li class="user-list-item">
                            <div class="avatar avatar-online">
                                <img src="assets/img/avatar-8.jpg" class="rounded-circle" alt="image">
                            </div>
                            <div class="users-list-body">
                                <div>
                                    <h5>Regina Dickerson</h5>
                                    <p>It seems logical that the strategy of providing!</p>
                                </div>
                                <div class="last-chat-time">
                                    <small class="text-muted">14:45 pm</small>
                                    <div class="chat-toggle mt-1">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" href="#">
                                                <i class="fas fa-ellipsis-h ellipse_header"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a href="#" class="dropdown-item">Open</a>
                                                <a href="#" class="dropdown-item dream_profile_menu">Profile</a>
                                                <a href="#" class="dropdown-item">Add to archive</a>
                                                <div class="dropdown-divider"></div>
                                                <a href="#" class="dropdown-item">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="user-list-item">
                            <div>
                                <div class="avatar avatar-away">
                                    <img src="assets/img/avatar-7.jpg" class="rounded-circle" alt="image">
                                </div>
                            </div>
                            <div class="users-list-body">
                                <div>
                                    <h5>Kevin Howard</h5>
                                    <p>It seems logical that the strategy of providing!</p>
                                </div>
                                <div class="last-chat-time">
                                    <small class="text-muted">08:45 pm</small>
                                    <div class="chat-toggle mt-1">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" href="#">
                                                <i class="fas fa-ellipsis-h ellipse_header"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a href="#" class="dropdown-item">Open</a>
                                                <a href="#" data-navigation-target="contact-information"
                                                    class="dropdown-item dream_profile_menu">Profile</a>
                                                <a href="#" class="dropdown-item">Add to archive</a>
                                                <div class="dropdown-divider"></div>
                                                <a href="#" class="dropdown-item">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="user-list-item">
                            <div>
                                <div class="avatar avatar-offline">
                                    <img src="assets/img/avatar-1.jpg" class="rounded-circle" alt="image">
                                </div>
                            </div>
                            <div class="users-list-body">
                                <div>
                                    <h5 class="list_group_notread">Eric Knight</h5>
                                    <p>Welcome to the community mate! 👍</p>
                                </div>
                                <div class="last-chat-time">
                                    <small class="text-muted">Yesterday</small>
                                    <div class="new-message-count"></div>
                                    <div class="chat-toggle mt-1">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" href="#">
                                                <i class="fas fa-ellipsis-h ellipse_header"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a href="#" class="dropdown-item">Open</a>
                                                <a href="#" data-navigation-target="contact-information"
                                                    class="dropdown-item dream_profile_menu">Profile</a>
                                                <a href="#" class="dropdown-item">Add to archive</a>
                                                <div class="dropdown-divider"></div>
                                                <a href="#" class="dropdown-item">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="user-list-item unread">
                            <div>
                                <div class="avatar avatar-online">
                                    <img src="assets/img/avatar-2.jpg" class="rounded-circle" alt="image">
                                </div>
                            </div>
                            <div class="users-list-body">
                                <div>
                                    <h5>Scott Albright</h5>
                                    <p>I remember everything mate. See you later 🤘</p>
                                </div>
                                <div class="last-chat-time">
                                    <small class="text-muted">06:18 am</small>
                                    <div class="chat-toggle mt-1">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" href="#">
                                                <i class="fas fa-ellipsis-h ellipse_header open_drop"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a href="#" class="dropdown-item">Open</a>
                                                <a href="#" data-navigation-target="contact-information"
                                                    class="dropdown-item dr_menu">Profile</a>
                                                <a href="#" class="dropdown-item">Add to archive</a>
                                                <div class="dropdown-divider"></div>
                                                <a href="#" class="dropdown-item">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="user-list-item">
                            <div>
                                <div class="avatar avatar-away">
                                    <img src="assets/img/avatar-3.jpg" class="rounded-circle" alt="image">
                                </div>
                            </div>
                            <div class="users-list-body">
                                <div>
                                    <h5 class="list_group_notread">Irene Perkins</h5>
                                    <p><i class="fa fa-camera mr-1"></i> I will miss you, too, my dear!</p>
                                </div>
                                <div class="last-chat-time">
                                    <small class="text-muted">Yesterday</small>
                                    <div class="new-message-count"></div>
                                    <div class="chat-toggle mt-1">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" href="#">
                                                <i class="fas fa-ellipsis-h ellipse_header"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a href="#" class="dropdown-item">Open</a>
                                                <a href="#" data-navigation-target="contact-information"
                                                    class="dropdown-item dream_profile_menu" >Profile</a>
                                                <a href="#" class="dropdown-item">Add to archive</a>
                                                <div class="dropdown-divider"></div>
                                                <a href="#" class="dropdown-item">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="user-list-item">
                            <div>
                                <div class="avatar avatar-online">
                                    <img src="assets/img/avatar-4.jpg" class="rounded-circle" alt="image">
                                </div>
                            </div>
                            <div class="users-list-body">
                                <div>
                                    <h5 class="list_group_notread">Carol Andre</h5>
                                    <p><i class="fa fa-camera mr-1"></i> Photo</p>
                                </div>
                                <div class="last-chat-time">
                                    <small class="text-muted">Yesterday</small>
                                    <div class="new-message-count"></div>
                                    <div class="chat-toggle mt-1">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" href="#">
                                                <i class="fas fa-ellipsis-h ellipse_header"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a href="#" class="dropdown-item">Open</a>
                                                <a href="#" data-navigation-target="contact-information"
                                                    class="dropdown-item dream_profile_menu">Profile</a>
                                                <a href="#" class="dropdown-item">Add to archive</a>
                                                <div class="dropdown-divider"></div>
                                                <a href="#" class="dropdown-item">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            &lt;!&ndash; / Chats sidebar &ndash;&gt;
        </div>-->
        <!-- /Sidebar group -->
        <ng-template>Content to render when condition is false.</ng-template>
        <!-- Chat -->
        <div class="chat" id="middle" >
            <div class="chat-header ">
                <div class="user-details" style="width: 100%;position: relative;">
                    <!--<div class="d-lg-none ml-2">
                        <ul class="list-inline mt-2 mr-2">
                            <li class="list-inline-item">
                                <a class="text-muted px-0 left_side" href="#" data-chat="open">
                                    <i class="fas fa-arrow-left"></i>
                                </a>
                            </li>
                        </ul>
                    </div>-->
                    <figure class="avatar ml-1">
                        <img src="assets/img/icon.png" style="border: 1px solid #48247e;" class="rounded-circle" alt="image">
                    </figure>
                    <div class="mt-1">
                        <h5 class="mb-1">My Aga</h5>
                        <small class="text-muted mb-2">
                            Actif
                        </small>
                    </div>
                    <div class="chat-options right_side" style="position: absolute;right: 0;">
                        <ul class="list-inline">
                            <!--                            <li class="list-inline-item" data-toggle="tooltip" title="" data-original-title="Voice call">-->
                            <!--                                <a href="javascript:void(0)" class="btn btn-outline-light" data-toggle="modal" data-target="#voice_call">-->
                            <!--                                    <i class="fas fa-phone-alt voice_chat_phone"></i>-->
                            <!--                                </a>-->
                            <!--                            </li>-->
                            <!--                            <li class="list-inline-item" data-toggle="tooltip" title="" data-original-title="Video call">-->
                            <!--                                <a href="javascript:void(0)" class="btn btn-outline-light" data-toggle="modal" data-target="#video_call">-->
                            <!--                                    <i class="fas fa-video"></i>-->
                            <!--                                </a>-->
                            <!--                            </li>-->
                            <li class="list-inline-item">
                                <a class="btn btn-outline-light" href="#" data-toggle="dropdown">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <!-- <a href="#" class="dropdown-item dream_profile_menu">Profile</a> -->
                                    <a href="#" class="dropdown-item">Déconnexion</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="chat-body" style="background: url('/assets/img/preview16.jpg') no-repeat; background-size: cover; ">
                <!-- Section image
                <div class="messages">
                    <div class="chats">
                        <div class="chat-content">
                            <div class="message-content">
                                <div class="chat-image">
                                    <img class="navbar-brand-regular" src="/assets/img/logo-aga.png" alt="brand-logo">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="messages">
                    <div class="chats chats-right">
                        <div class="chat-content">
                            <div class="message-content">

                            </div>
                        </div>
                    </div>
                </div>
                 End section image -->
                <!--{{isNewConversattion(chatService.messages)}}-->
                <div class="modal-shadow" style="display: none;" id="modal_shadow"></div>
                <div class="all-message">
                    <div class="messages" >
                        <div class="chats">
                            <div class="chat-content">
                                <div class="message-content message-box" >
                                    Bonjour {{displayName}} <i class="em-svg em-hand"></i>, <br>
                                    Bienvenue sur My Aga​!
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Modal -->
                    <div id="exampleModal" tabindex="-1" class="obj_modal">
                        <h4>Entrez votre objectif</h4>
                        <hr>
                        <br>
                        <form action="">
                            <div class="form-group">
                                <textarea class="form-control" id="other-objective" [(ngModel)]="otherObjective" rows="3" name="newObjective" style="padding: 5px;"></textarea><br>
                                <div class="text-center">

                                    <button class="btn btn-outline-primary" (click)="newObjective()" style="padding: 7px; border: 1px solid; margin-right: 5px"> Enregistrer</button>
                                    <button class="btn btn-default" (click)="closeModal()"> Annuler </button>
                                </div>
                            </div>
                        </form>

                    </div>

                    <!-- End Modal -->

                    <div class="all-message" *ngFor="let diagnostic of diagnosticsOk;" >
                        <div class="messages">
                            <div class="chats">
                                <div class="chat-content">
                                    <div class="message-content message-box" >
                                       {{diagnostic.question.label}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="messages">
                            <div class="chats chats-right">
                                <div class="chat-content">
                                    <div class="message-content">
                                        {{diagnostic.response}}
                                    </div>
                                    <!--<div class="chat-time">
                                        <div>
                                            <div class="time">{{message.dateUpdated | date: 'h:mm a'}}<i><img src="../assets/img/double-tick.png" alt=""></i></div>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="messages" *ngIf="currentDiagnostic!==null && currentDiagnostic.styleType=='checkbox'">
                        <div class="chats">
                            <div class="chat-content">
                                <div class="message-content message-box" >
                                    {{currentDiagnostic.question.label}}
                                </div>
                            </div>
                        </div>
                        <div class="chats">
                            <div class="chat-content">
                                <div class="message-content" >
                                    <section class="row" style="padding: 10px;">
                                        <div *ngFor="let option of currentDiagnostic.question.options; let i=index;" class="target-box target{{i}}">
                                            <div  class="boxes">
                                                <div class=" objective{{i}} box-objective1 text-center">
                                                    <input type="checkbox" name="objectifs[]" (click)="setObejectifs(option)" value="{{option}}" class="d-none rightCheckbox" id="option{{i}}">
                                                    <label for="option{{i}}" class="label-objective" id="label{{i}}">{{option}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <form (submit)="sendMessage()" autocomplete="off" name="chatForm" id="chatForm" class="mx-auto" style="width: auto;margin-top:20px; margin-bottom:0px;">
                                            <button class="btn send-btn mx-auto" type="submit" [disabled]="!formOk" style="width: 100px; border-radius: 25px">
                                                <i class="fab fa-telegram-plane"></i>&nbsp; valider
                                            </button>
                                        </form>

                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="messages" *ngIf="currentDiagnostic!==null && currentDiagnostic.styleType=='radio'">
                        <div class="chats">
                            <div class="chat-content">
                                <div class="message-content message-box" >
                                    {{currentDiagnostic.question.label}}
                                </div>
                            </div>
                        </div>
                        <div class="chats">
                            <div class="chat-content">
                                <div class="message-content message-box" >
                                    <!--<section style="padding: 10px;">
                                        <ul class="list">
                                            <li class="list__item" *ngFor="let option of currentDiagnostic.question.options; let j=index;" >
                                                <input type="radio" (click)="setLadder(option)" class="radio-btn" name="choice" id="option{{j}}" />
                                                <label for="option{{j}}" class="label">{{option}}</label>
                                            </li>
                                        </ul>
                                    </section>-->
                                    <section>
                                        <fieldset *ngFor="let option of currentDiagnostic.question.options; let j=index;">
                                            <input name="animals" type="radio" value="option{{j}}" id="option{{j}}" (click)="setLadder(option)">
                                            <label class="label1" for="option{{j}}">{{option}}</label>
                                            <span class="animal"></span>
                                        </fieldset>
                                        <form (submit)="sendMessage()" autocomplete="off" name="chatForm" id="chatForm" class="mx-auto" style="width: auto; margin-top:20px; margin-bottom:0px;">
                                            <button class="btn send-btn mx-auto" type="submit" [disabled]="!formOk" style="width: 100px; border-radius: 25px">
                                                <i class="fab fa-telegram-plane"></i>&nbsp; Valider
                                            </button>
                                        </form>
                                    </section>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="contain mx-auto" *ngIf="loading" style="width: 50%;">
                    <app-spinner></app-spinner>
                </div>
            </div>

            <div id="for_scroll">&nbsp;</div>
            <div class="chat-footer">
                <form (submit)="sendMessage()" autocomplete="off" name="chatForm" id="chatForm" style="display: none;">

                    <input type="text" name="message" [disabled]="disableSendMessage" id="chatMessage" style="padding-left: 20px;" (keyup)="enableSubmit()" class="form-control chat_form" [(ngModel)]="chatMessage" placeholder="Choisissez une réponse...">
                    <div class="form-buttons">
                        <button class="btn" type="button" *ngIf="!isEmojiPickerVisible" (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">
                            <i class="far fa-smile"></i>
                        </button>
                        <button type="button" class="btn" *ngIf="isEmojiPickerVisible" (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">ABC</button>&nbsp;
                        <button class="btn" type="button" data-toggle="modal" data-target="#drag_files">
                            <i class="fas fa-paperclip"></i>
                        </button>
                        <!-- <button class="btn" type="button">
                            <i class="fas fa-microphone-alt"></i>
                        </button> -->
                        <button class="btn send-btn" type="submit" [disabled]="!formOk">
                            <i class="fab fa-telegram-plane"></i>
                        </button>
                    </div>
                    <div class="position-absolute" style="right: 0;bottom: 0;">
                        <emoji-mart class="emoji-mart" title="Choisissez votre emoji" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" [style]="{ position: 'absolute', bottom: '60px', right: '0px' }" [i18n]="{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }"></emoji-mart>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Chat -->
        <!--
           <div id="drag_files" class="modal fade" role="dialog">
               <div class="modal-dialog modal-md modal-dialog-centered">
                   <div class="modal-content">
                       <div class="modal-header">
                           <h4 class="modal-title">Drag and drop files upload</h4>
                           <button type="button" class="close" data-dismiss="modal">&times;</button>
                       </div>
                       <div class="modal-body">
                           <form id="js-upload-form">
                               <div class="upload-drop-zone" id="drop-zone">
                                   <i class="fa fa-cloud-upload fa-2x"></i> <span class="upload-text">Just drag and
                                       drop files here</span>
                               </div>
                           </form>
                           <div class="text-center mt-0">
                               <button class="btn newgroup_create m-0" data-dismiss="modal">Add to upload</button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           &lt;!&ndash; /Upload Documents &ndash;&gt;

           &lt;!&ndash; Voice call &ndash;&gt;
           <div class="modal fade voice_pop" id="voice_call" role="document">
               <div class="modal-dialog modal-dialog-centered">
                   <div class="modal-content voice_content ml-3">
                       <div class="modal-body voice_body">
                           <div class="call-box incoming-box">
                               <div class="call-wrapper">
                                   <div class="call-inner">
                                       <div class="call-user">
                                           <img alt="User Image" src="assets/img/avatar-2.jpg" class="call-avatar">
                                           <h4>Tobbias Williams</h4>
                                           <span class="chat_cal">calling...</span>
                                       </div>
                                       <div class="call-items">
                                           <a href="#" class="btn call-item call-end" data-dismiss="modal">
                                               <i class="fas fa-phone-alt phone_icon"></i>
                                           </a>
                                           <a href="#" class="btn call-item call-start" data-dismiss="modal">
                                               <i class="fas fa-phone-alt"></i>
                                           </a>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           &lt;!&ndash; /Voice call &ndash;&gt;

           &lt;!&ndash; Video Call &ndash;&gt;
           <div class="modal fade voice_pop" id="video_call" role="document">
               <div class="modal-dialog modal-dialog-centered">
                   <div class="modal-content voice_content ml-3">
                       <div class="modal-body voice_body">
                           <div class="call-box incoming-box">
                               <div class="call-wrapper">
                                   <div class="call-inner">
                                       <div class="call-user">
                                           <img alt="User Image" src="assets/img/avatar-2.jpg" class="call-avatar">
                                           <h4>Tobbias Williams</h4>
                                           <span class="chat_cal">calling...</span>
                                       </div>
                                       <div class="call-items">
                                           <a href="#" class="btn call-item call-end" data-dismiss="modal">
                                               <i class="fas fa-phone-alt phone_icon"></i>
                                           </a>
                                           <a href="#" class="btn call-item call-start" data-dismiss="modal">
                                               <i class="fas fa-video"></i>
                                           </a>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           &lt;!&ndash; /Video Call &ndash;&gt;

           &lt;!&ndash; Chat New Modal &ndash;&gt;
           <div class="modal fade" id="chat-new">
               <div class="modal-dialog modal-dialog-centered">
                   <div class="modal-content">
                       <div class="modal-header">
                           <h5 class="modal-title">
                               Add Friends
                           </h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                               <i class="fas fa-times close_icon"></i>
                           </button>
                       </div>
                       <div class="modal-body">
                           &lt;!&ndash; Card &ndash;&gt;
                           <form action="new-friends">
                               <div class="form-group">
                                   <label>Phone</label>
                                   <input class="form-control form-control-lg group_formcontrol" name="new-chat-title" type="text" placeholder="+39 02 87 21 43 19">
                               </div>
                               <div class="form-group">
                                   <label>Email</label>
                                   <input class="form-control form-control-lg group_formcontrol" name="new-chat-title" type="email" placeholder="tobiaswilliams@gmail.com">
                               </div>
                           </form>
                           &lt;!&ndash; Card &ndash;&gt;
                           <div class="form-row profile_form mt-3 mb-1">
                               &lt;!&ndash; Button &ndash;&gt;
                               <button type="button" class="btn btn-block newgroup_create mb-0" data-dismiss="modal" aria-label="Close">
                                   Submit
                               </button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           &lt;!&ndash; /Chat New Modal &ndash;&gt;

           &lt;!&ndash; New group modal &ndash;&gt;
           <div class="modal fade" id="new_group">
               <div class="modal-dialog modal-dialog-centered">
                   <div class="modal-content">
                       <div class="modal-header">
                           <h5 class="modal-title">
                               New Group
                           </h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                               <i class="fas fa-times close_icon"></i>
                           </button>
                       </div>
                       <div class="modal-body">
                           &lt;!&ndash; Tabs &ndash;&gt;
                           <ul class="nav nav-tabs nav-justified newgroup_ul mt-0" role="tablist">
                               <li class="nav-item">
                                   <a href="#create-group-details" class="nav-link active" data-toggle="tab" role="tab" aria-selected="true">Details</a>
                               </li>

                               <li class="nav-item">
                                   <a href="#create-group-members" class="nav-link" data-toggle="tab" role="tab" aria-selected="false">Members</a>
                               </li>
                           </ul>
                           &lt;!&ndash; Tabs &ndash;&gt;
                           &lt;!&ndash; Create chat &ndash;&gt;
                           <div class="tab-content" role="tablist">
                               &lt;!&ndash; Chat details &ndash;&gt;
                               <div id="create-group-details" class="tab-pane fade show active" role="tabpanel">
                                   <form action="#">
                                       <div class="form-group">
                                           <label>Name</label>
                                           <input class="form-control form-control-lg group_formcontrol" name="new-chat-title" type="text" placeholder="Group Name">
                                       </div>
                                       <div class="form-group">
                                           <label for="new-chat-topic">Topic (optional)</label>
                                           <input class="form-control form-control-lg group_formcontrol" name="new-chat-topic" id="new-chat-topic" type="text" placeholder="Group Topic">
                                       </div>
                                       <div class="form-group mb-0">
                                           <label for="new-chat-description">Description</label>
                                           <textarea class="form-control form-control-lg group_control_text" name="new-chat-description" id="new-chat-description" rows="6" placeholder="Group Description"></textarea>
                                       </div>
                                   </form>
                               </div>
                               &lt;!&ndash; Chat details &ndash;&gt;

                               &lt;!&ndash; Chat Members &ndash;&gt;
                               <div id="create-group-members" class="tab-pane fade create-group-members" role="tabpanel">
                                   <nav class="list-group list-group-flush mb-n6">
                                       &lt;!&ndash; Search &ndash;&gt;
                                       <form class="mb-3 newgroup_content">
                                           <div class="input-group">
                                               <input type="text" class="form-control form-control-lg newgroup_search" placeholder="Search for messages or users..." aria-label="Search for messages or users...">
                                               <div class="input-group-append">
                                                   <button class="btn btn-lg btn-ico btn-secondary btn-minimal newgroup_search_btn" type="submit">
                                                       <i class="fas fa-search newgroup_fa_search"></i>
                                                   </button>
                                               </div>
                                           </div>
                                       </form>
                                       &lt;!&ndash; Search &ndash;&gt;
                                       <div class="mb-6">
                                           <small class="text-uppercase">A</small>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="card mb-6 group_card_mb">
                                           <div class="card-body">
                                               <div class="media">
                                                   <div class="avatar avatar-online mr-5">
                                                       <img class="avatar-img group_image" src="assets/img/avatar-7.jpg" alt="Anna Bridges">
                                                   </div>
                                                   <div class="media-body align-self-center mr-6 group_card_media">
                                                       <h6 class="mb-0">Anna Bridges</h6>
                                                       <small class="text-muted">Online</small>
                                                   </div>
                                                   <div class="align-self-center ml-auto">
                                                       <div class="custom-control custom-checkbox">
                                                           <input class="custom-control-input" id="id-user-1" type="checkbox">
                                                           <label class="custom-control-label" for="id-user-1"></label>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           &lt;!&ndash; Label &ndash;&gt;
                                           <label class="stretched-label" for="id-user-1"></label>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="mb-6">
                                           <small class="text-uppercase">B</small>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="card mb-6 group_card_mb">
                                           <div class="card-body">
                                               <div class="media">
                                                   <div class="avatar mr-5">
                                                       <img class="avatar-img group_image" src="assets/img/avatar-1.jpg" alt="Brian Dawson">
                                                   </div>
                                                   <div class="media-body align-self-center mr-6 group_card_media">
                                                       <h6 class="mb-0">Brian Dawson</h6>
                                                       <small class="text-muted">last seen 2 hours ago</small>
                                                   </div>
                                                   <div class="align-self-center ml-auto">
                                                       <div class="custom-control custom-checkbox">
                                                           <input class="custom-control-input" id="id-user-2" type="checkbox">
                                                           <label class="custom-control-label" for="id-user-2"></label>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           &lt;!&ndash; Label &ndash;&gt;
                                           <label class="stretched-label" for="id-user-2"></label>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="mb-6">
                                           <small class="text-uppercase">L</small>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="card mb-6 group_card_mb">
                                           <div class="card-body">
                                               <div class="media">
                                                   <div class="avatar mr-5">
                                                       <img class="avatar-img group_image" src="assets/img/avatar-2.jpg" alt="Leslie Sutton">
                                                   </div>
                                                   <div class="media-body align-self-center mr-6 group_card_media">
                                                       <h6 class="mb-0">Leslie Sutton</h6>
                                                       <small class="text-muted">last seen 3 days ago</small>
                                                   </div>
                                                   <div class="align-self-center ml-auto">
                                                       <div class="custom-control custom-checkbox">
                                                           <input class="custom-control-input" id="id-user-3" type="checkbox">
                                                           <label class="custom-control-label" for="id-user-3"></label>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           &lt;!&ndash; Label &ndash;&gt;
                                           <label class="stretched-label" for="id-user-3"></label>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="mb-6">
                                           <small class="text-uppercase">M</small>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="card mb-6 group_card_mb">
                                           <div class="card-body">
                                               <div class="media">
                                                   <div class="avatar mr-5">
                                                       <img class="avatar-img group_image" src="assets/img/avatar-3.jpg" alt="Matthew Wiggins">
                                                   </div>
                                                   <div class="media-body align-self-center mr-6 group_card_media">
                                                       <h6 class="mb-0">Matthew Wiggins</h6>
                                                       <small class="text-muted">last seen 3 days ago</small>
                                                   </div>
                                                   <div class="align-self-center ml-auto">
                                                       <div class="custom-control custom-checkbox">
                                                           <input class="custom-control-input" id="id-user-4" type="checkbox">
                                                           <label class="custom-control-label" for="id-user-4"></label>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           &lt;!&ndash; Label &ndash;&gt;
                                           <label class="stretched-label" for="id-user-4"></label>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="mb-6">
                                           <small class="text-uppercase">S</small>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="card mb-6 group_card_mb">
                                           <div class="card-body">
                                               <div class="media">
                                                   <div class="avatar mr-5">
                                                       <img class="avatar-img group_image" src="assets/img/avatar-4.jpg" alt="Simon Hensley">
                                                   </div>
                                                   <div class="media-body align-self-center mr-6 group_card_media">
                                                       <h6 class="mb-0">Simon Hensley</h6>
                                                       <small class="text-muted">last seen 3 days ago</small>
                                                   </div>
                                                   <div class="align-self-center ml-auto">
                                                       <div class="custom-control custom-checkbox">
                                                           <input class="custom-control-input" id="id-user-5" type="checkbox">
                                                           <label class="custom-control-label" for="id-user-5"></label>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           &lt;!&ndash; Label &ndash;&gt;
                                           <label class="stretched-label" for="id-user-5"></label>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="mb-6">
                                           <small class="text-uppercase">W</small>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="card mb-6 group_card_mb">
                                           <div class="card-body">
                                               <div class="media">
                                                   <div class="avatar mr-5">
                                                       <img class="avatar-img group_image" src="assets/img/avatar-5.jpg" alt="William Wright">
                                                   </div>
                                                   <div class="media-body align-self-center mr-6 group_card_media">
                                                       <h6 class="mb-0">William Wright</h6>
                                                       <small class="text-muted">last seen 3 days ago</small>
                                                   </div>
                                                   <div class="align-self-center ml-auto">
                                                       <div class="custom-control custom-checkbox">
                                                           <input class="custom-control-input" id="id-user-6" type="checkbox">
                                                           <label class="custom-control-label" for="id-user-6"></label>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           &lt;!&ndash; Label &ndash;&gt;
                                           <label class="stretched-label" for="id-user-6"></label>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="card mb-6 group_card_mb">
                                           <div class="card-body">
                                               <div class="media">
                                                   <div class="avatar mr-5">
                                                       <img class="avatar-img group_image" src="assets/img/avatar-6.png" alt="William Greer">
                                                   </div>
                                                   <div class="media-body align-self-center mr-6 group_card_media">
                                                       <h6 class="mb-0">William Greer</h6>
                                                       <small class="text-muted">last seen 10 minutes ago</small>
                                                   </div>
                                                   <div class="align-self-center ml-auto">
                                                       <div class="custom-control custom-checkbox">
                                                           <input class="custom-control-input" id="id-user-7" type="checkbox">
                                                           <label class="custom-control-label" for="id-user-7"></label>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           &lt;!&ndash; Label &ndash;&gt;
                                           <label class="stretched-label" for="id-user-7"></label>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="mb-6">
                                           <small class="text-uppercase">Z</small>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                       <div class="card mb-6 group_card_mb">
                                           <div class="card-body">
                                               <div class="media">
                                                   <div class="avatar mr-5">
                                                       <img class="avatar-img group_image" src="assets/img/avatar-8.jpg" alt="Zane Mayes">
                                                   </div>
                                                   <div class="media-body align-self-center mr-6 group_card_media">
                                                       <h6 class="mb-0">Zane Mayes</h6>
                                                       <small class="text-muted">last seen 3 days ago</small>
                                                   </div>
                                                   <div class="align-self-center ml-auto">
                                                       <div class="custom-control custom-checkbox">
                                                           <input class="custom-control-input" id="id-user-8" type="checkbox">
                                                           <label class="custom-control-label" for="id-user-8"></label>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           &lt;!&ndash; Label &ndash;&gt;
                                           <label class="stretched-label" for="id-user-8"></label>
                                       </div>
                                       &lt;!&ndash; Friend &ndash;&gt;
                                   </nav>
                               </div>
                               &lt;!&ndash; Chat Members &ndash;&gt;
                           </div>
                           &lt;!&ndash; Create chat &ndash;&gt;
                           &lt;!&ndash; Button &ndash;&gt;
                           <div class="pt-3">
                               <div class="container text-center">
                                   <button class="btn btn-block newgroup_create mb-1 mt-0" type="submit" data-dismiss="modal">Create group</button>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           &lt;!&ndash; /New group modal &ndash;&gt;

           &lt;!&ndash; Profile Modal &ndash;&gt;
           <div class="modal fade" id="profile_modal">
               <div class="modal-dialog modal-dialog-centered">
                   <div class="modal-content">
                       <div class="modal-header">
                           <h5 class="modal-title">
                               Profile
                           </h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                               <i class="fas fa-times close_icon"></i>
                           </button>
                       </div>
                       <div class="modal-body">
                           &lt;!&ndash; Card &ndash;&gt;
                           <div class="card mb-6 profile_Card">
                               <div class="card-body">
                                   <div class="text-center py-6">
                                       &lt;!&ndash; Photo &ndash;&gt;
                                       <div class="avatar avatar-xl mb-3">
                                           <img class="avatar-img rounded-circle mCS_img_loaded" src="assets/img/avatar-5.jpg" alt="">
                                       </div>
                                       <h5>John Janousek</h5>
                                       <p class="text-muted m-0">Last seen: Today</p>
                                   </div>
                               </div>
                           </div>
                           &lt;!&ndash; Card &ndash;&gt;
                           &lt;!&ndash; Card &ndash;&gt;
                           <form action="#" class="mt-3">
                               <div class="form-group">
                                   <label>Country</label>
                                   <input class="form-control form-control-lg group_formcontrol" name="new-chat-title" type="text" placeholder="Country Name">
                               </div>
                               <div class="form-group">
                                   <label>Phone</label>
                                   <input class="form-control form-control-lg group_formcontrol" name="new-chat-title" type="text" placeholder="+39 02 87 21 43 19">
                               </div>
                               <div class="form-group">
                                   <label>Email</label>
                                   <input class="form-control form-control-lg group_formcontrol" name="new-chat-title" type="email" placeholder="johnjanousek@gmail.com">
                               </div>
                           </form>
                           &lt;!&ndash; Card &ndash;&gt;
                           <div class="form-row profile_form mt-3 mb-1">
                               &lt;!&ndash; Button &ndash;&gt;
                               <button type="button" class="btn btn-block newgroup_create mb-0" data-dismiss="modal">
                                   Update Profile
                               </button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           &lt;!&ndash; /Profile Modal &ndash;&gt;

           &lt;!&ndash; Settings Modal &ndash;&gt;
           <div class="modal fade" id="settings_modal">
               <div class="modal-dialog modal-dialog-centered">
                   <div class="modal-content">
                       <div class="modal-header">
                           <h5 class="modal-title">
                               Settings
                           </h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                               <i class="fas fa-times close_icon"></i>
                           </button>
                       </div>
                       <div class="modal-body">
                           <div class="card-header position-relative account_details">
                               <a href="#" class="text-reset d-block stretched-link collapsed">
                                   <div class="row no-gutters align-items-center">
                                       &lt;!&ndash; Title &ndash;&gt;
                                       <div class="col">
                                           <h5>Account</h5>
                                           <p class="m-0">Update your profile details.</p>
                                       </div>
                                       &lt;!&ndash; Icon &ndash;&gt;
                                       <div class="col-auto">
                                           <i class="text-muted icon-md fas fa-user"></i>
                                       </div>
                                   </div>
                               </a>
                           </div>
                           <div class="card-header position-relative mt-3 security_details">
                               <a href="#" class="text-reset d-block stretched-link collapsed">
                                   <div class="row no-gutters align-items-center">
                                       &lt;!&ndash; Title &ndash;&gt;
                                       <div class="col">
                                           <h5>Security</h5>
                                           <p class="m-0">Update your profile details.</p>
                                       </div>
                                       &lt;!&ndash; Icon &ndash;&gt;
                                       <div class="col-auto">
                                           <i class="text-muted icon-md fas fa-crosshairs"></i>
                                       </div>
                                   </div>
                               </a>
                           </div>
                           <div class="mt-3">
                               <label for="profile-name">Name</label>
                               <input class="form-control form-control-lg profile_input group_formcontrol" name="profile-name" id="profile-name" type="text" placeholder="Type your name">
                           </div>
                           <div class="mt-4">
                               <label for="profile-name">Current Password</label>
                               <input class="form-control form-control-lg group_formcontrol" name="profile-name" id="profile-name_pswd" type="text" placeholder="Current Password">
                           </div>
                           <div class="mt-4">
                               <label for="profile-name">New Password</label>
                               <input class="form-control form-control-lg group_formcontrol" name="profile-name" id="profile-name_new" type="text" placeholder="New Password">
                           </div>
                           <div class="mt-4">
                               <label for="profile-name">Verify Password</label>
                               <input class="form-control form-control-lg group_formcontrol" name="profile-name" id="profile-name_prfname" type="text" placeholder="Verify Password">
                           </div>
                           <div class="mt-3 text-center">
                               <button class="btn btn-block newgroup_create mb-0" type="submit" data-dismiss="modal">Save Settings</button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           &lt;!&ndash; /Settings Modal &ndash;&gt;

           &lt;!&ndash; Right sidebar &ndash;&gt;
           <div class="right-sidebar right_sidebar_profile" id="middle1" style="display: none;">
               <div class="right-sidebar-wrap active">
                   <div class="contact-close_call mr-4 mt-4 text-right">
                       <a href="#" class="btn btn-outline-light close_profile close_profile4">
                           <i class="fas fa-times close_icon"></i>
                       </a>
                   </div>
                   <div class="sidebar-body">
                       <div class="pl-4 pr-4 mt-0 right_sidebar_logo">
                           <div class="text-center mb-3">
                               <figure class="avatar avatar-xl mb-3">
                                   <img src="assets/img/avatar-2.jpg" class="rounded-circle" alt="image">
                               </figure>
                               <h5 class="profile-name">Scott Albright</h5>
                           </div>
                           <div>

                               <div class="accordion-col">
                                   <div class="accordion-title">
                                       <h6 class="primary-title">Media (31) <i class="fas fa-chevron-right float-right"></i></h6>
                                   </div>
                                   <div class="accordion-content">
                                       <div class="media-lists">
                                           <div class="media-image">
                                               <img src="assets/img/media1.jpg" alt="">
                                           </div>
                                           <div class="media-image">
                                               <img src="assets/img/media2.jpg" alt="">
                                           </div>
                                           <div class="media-image">
                                               <img src="assets/img/media3.jpg" alt="">
                                           </div>
                                       </div>
                                   </div>
                                   <div class="accordion-title">
                                       <h6 class="primary-title">About and phone number <i class="fas fa-chevron-right float-right"></i></h6>
                                   </div>
                                   <div class="accordion-content">
                                       <p class="text-muted text-center mt-1">Help people to build websites and apps + grow awareness in social media 🔥</p>
                                       <div class="mt-2 text-center">
                                           <h6>Phone: <span class="text-muted ml-2">+(33 1) 45 55 01 10</span></h6>
                                       </div>
                                   </div>
                                   <div class="accordion-title">
                                       <h6 class="primary-title">Settings <i class="fas fa-chevron-right float-right"></i></h6>
                                   </div>
                                   <div class="accordion-content">
                                       <ul class="contact-action">
                                           <li class="block-user mt-1">
                                               <a href=""><i class="fas fa-ban mr-2 text-muted"></i>Block</a>
                                           </li>
                                           <li class="report-contact">
                                               <a href=""><i class="fas fa-thumbs-down mr-2"></i> Report Contact</a>
                                           </li>
                                           <li class="delete-chat">
                                               <a href=""><i class="fas fa-trash-alt mr-2"></i> Delete Chat</a>
                                           </li>
                                       </ul>
                                   </div>
                               </div>

                           </div>
                       </div>
                   </div>
               </div>
           </div>
          -->
    </div>
    <!-- /Content -->
    <!--    <app-install-app></app-install-app>-->
</div>
<!-- /Main Wrapper -->