import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.css']
})
export class IntroductionComponent implements OnInit {
  title = 'Introduction AgaCare';
  public httpError = false;
  public httpSuccess = false;
  public introduction = true;
  public step1 = false;
  public step2 = false;
  public errorMessage = '';
  public successMessage = '';
  public userName = '';
  public loading = false;

  constructor() { }

  ngOnInit(): void {

  }

  goToStep1(){
    this.introduction = false;
    this.step1 = true;
  }

  goToStep2(){
    this.introduction = false;
    this.step1 = false;
    this.step2 = true;
  }

}
