<div class="main">

    <!-- ***** Header End ***** -->

    <!-- ***** Welcome Area Start ***** -->
    <section id="home" class="section welcome-area bg-overlay d-flex align-items-center overflow-hidden">
        <div class="container">
            <div class="row align-items-center containc">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-12 col-lg-12">
                    <div class="welcome-intro" style="">
                        <img class="navbar-brand-regular"  src="/assets/img/logo-aga.png" alt="brand-logo">
                       <br><br> <h3 style="font-weight: 400">
                            Pour besoin de sécurité, merci de bien vouloir definir votre mot de passe.
                        </h3>
                        <br><br>
                        
                        <input class="aga-email"  placeholder="Entrez le mot de passe"  type="password" #password >
                        <br><br>
                        <input class="aga-email"  placeholder="Confirmer le mot de passe"  type="password" #confirmPassword >
                        <br><br>
                        <!-- Subscribe Form -->
                        <div class="subscribe-form align-items-center d-none d-sm-block">
                            <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                            <button type="button" class="btn btn-bordered" style="margin-left: auto;margin-right: auto;">
                                Valider
                            </button>
                            <div class="row" style="position: fixed;bottom: 0; text-align: center;">
                                <div class="col-md-10">
                                    <div>
                                        <br>
                                        <i style="font-size: 12px; color: #565656; font-family: Poppins">
                                            <sup>*</sup>En cliquant ici initier une discussion sécurisée et confidentielle avec Aga via

                                            Facebook Messenger.  Pour besoin de confidentialité, deux étapes de confirmation vous seront demandées par Facebook pour  joindre Aga.

                                            Merci de votre patience
                                        </i>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                        </div>
                        <!--<div>
                            <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                        </div>-->
                    </div>
                </div>

                <div class="col-12 d-block d-sm-none"><br>
                    <div class="subscribe-form align-items-center text-center" ><br>
                        <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                        <button type="button" class="btn btn-bordered" style="margin-left: auto;margin-right: auto;">
                            Valider
                        </button>

                    </div>
                    <div class="row" style="position: fixed;bottom: 0;">
                        <div class="col-md-12">
                            <div>
                                <br>
                                <i style="font-size: 10px;color: #565656; font-family: Poppins">
                                    <sup>*</sup>
                                    En cliquant ici initier une discussion sécurisée et confidentielle avec Aga via

                                    Facebook Messenger.  Pour besoin de confidentialité, deux étapes de confirmation vous seront demandées par Facebook pour  joindre Aga.

                                     Merci de votre patience
                                </i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- Shape Bottom -->

    </section>
    <!-- ***** Welcome Area End ***** -->

</div>