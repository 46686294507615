<div class="main">

    <!-- ***** Header End ***** -->

    <!-- ***** Welcome Area Start ***** -->
    <section id="home" class="section welcome-area bg-overlay d-flex align-items-center overflow-hidden">
        <!-- <div class="container mx-auto" *ngIf="!isLoaded" style="margin-bottom: 110px;">
            <app-spinner></app-spinner>
        </div> -->
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#49247e" type="square-jelly-box" [fullScreen]="true">
            <p style="color: white"> Chargement... </p>
        </ngx-spinner>

        <div class="container mx-auto" style="margin-bottom: 110px;">
            <div class="row align-items-center containc">
                <div class="col-12 col-md-12 col-lg-12">
                    <div class="welcome-intro">
                        <img class="navbar-brand-regular logo" src="/assets/img/logo-aga.png" alt="brand-logo">
                    </div>
                </div>
                <div class="col-md-12 col-12 col-lg-12 " style="text-align: center;">
                    <div class="notification notification-success" *ngIf="httpSuccess" style="text-align: center;">
                        {{successMessage}}
                    </div>
                </div>

                <!-- prehome Intro Start -->
                <div *ngIf="prehome" class="welcome animate__animated animate__slideInRight mx-auto" id="prehome">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <h3 style="font-weight: 400">Juste un message à Aga.<br> Et <b>commencez</b> à vous sentir <b>mieux</b></h3>
                            <input class="aga-email" placeholder="Entrez votre pseudo" type="text" #pseudoName>
                            <br><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center text-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="checkPseudo(pseudoName.value)" class="btn-bordered">
                                    <span [ngClass]="loading?'loading':''"></span> Parlez à Aga
                                </button>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Welcome Intro End -->

                <!-- Welcome Intro Start -->
                <div *ngIf="welcome" class="welcome animate__animated animate__slideInRight mx-auto" id="welcome">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <h3 style="font-weight: 400">Juste un message à Aga.<br> Et <b>commencez</b> à vous sentir <b>mieux</b></h3>
                            <input class="aga-email" placeholder="Entrez votre email" type="text" #email>
                            <br><span [ngClass]="validateEmail?'d-none':'d-block error-message'">Adresse email incorrect</span><br>
                            <input class="aga-email" placeholder="Entrez le mot de passe" [type]="showPassword?'text':'password'" #password style="margin-left:15px">
                            <i [ngClass]="showPassword?'fas fa-eye-slash toggle-password':'fas fa-eye toggle-password'" (click)="togglePassword()" style="color: #000; cursor: pointer;position: relative; right: 25px;top: 0;"></i>
                            <span [ngClass]="validPassword?'d-block':'d-none'"><br>Mot de passe invalide<br></span>
                            <br><a href="#" (click)="renewPassword()" style="display: block; margin-top: 10px;">Mot de passe oublié?</a><br>

                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center text-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="login(email.value,password.value)" class="btn-bordered">
                                    <span [ngClass]="loading?'loading':''"></span> Parlez à Aga
                                </button>
                                <br><a href="#" (click)="newAccount()" style="display: block; margin-top: 10px;">Créer votre compte</a><br>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Welcome Intro End -->

                <!-- View CGU  Start -->
                <div *ngIf="cgu" class="animate__animated animate__slideInRight mx-auto" id="cgU" >
                    <div class="col-12 col-md-12 col-lg-12" style="padding: 15px;">
                        <div style="width:100%" class=" row text-left">
                            <div class="col-md-12 ml-4">
                                <button (click)="backToCreateAccount()" class="btn-outline-primary " style="padding: 2px 20px;;"> Retour </button>
                            </div>
                        </div>
                        <h3 class="text-center" style="color:#4a257f;">Conditions générales d'utilisation</h3>
                        <p >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut eros ornare, pulvinar arcu vitae, pulvinar erat. Nullam volutpat convallis enim, eu volutpat nibh maximus at. Vestibulum gravida semper enim, id ullamcorper leo egestas at. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec consequat viverra laoreet. Donec mattis leo enim, non ultricies purus posuere in. Sed finibus ac lorem nec vestibulum. Praesent eleifend dapibus risus, a ultrices felis porta sed.
                        </p>
                        <p>hasellus rutrum laoreet nunc a ullamcorper. Sed vitae ex tempor, tempor augue ut, gravida enim. Mauris metus est, aliquet quis euismod posuere, posuere eu turpis. Praesent cursus feugiat sapien, et facilisis dolor aliquet ac. Duis sagittis quis enim in aliquam. Pellentesque scelerisque magna quis eleifend dictum. Phasellus porta neque diam, vel viverra turpis volutpat et. Donec tempor a mi in finibus. In eu varius sapien. Curabitur sagittis convallis tincidunt. Vestibulum id felis sed metus tristique pellentesque. Nullam in metus vitae nisi venenatis sodales.
                        </p>
                    </div>
                </div>
                <!-- View CGU End -->

                <!-- Has password section Start -->
                <div *ngIf="hasPassword" class="hasPassword animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro" style="max-width: 600px; margin: auto;">
                            <br><br>
                            <h4 style="font-weight: 400">
                                Entrez votre mot de passe
                            </h4>
                            <br><br>

                            <input class="aga-email" placeholder="Entrez le mot de passe" type="text" #password2>
                            <span [ngClass]="validPassword?'d-block':'d-none'"><br>Mot de passe invalide<br></span>
                            <br><a href="#" (click)="renewPassword()" style="display: block; margin-top: 10px;">Mot de passe oublié?</a><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="login(true,password2.value)" class="btn-bordered" style="margin-left: auto;margin-right: auto;">
                                    <span [ngClass]="loading?'loading':''"></span> Demarrer le chat
                                </button>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Has password section End -->

                <!-- Forget password start -->
                <div *ngIf="forgetPassword" class="forgetPassword  animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <br><br>
                            <div style="width:100%" class=" row text-left">
                                <div class="col-md-12 ml-4">
                                    <button (click)="backToWelcome()" class="btn-outline-primary " style="padding: 2px 20px;;"> Retour </button>
                                </div>
                            </div>
                            <br>
                            <h3 style="font-weight: 400">Entrez votre adresse email afin de réinitialiser votre mot de passe</h3>
                            <br><br>

                            <input class="aga-email" placeholder="Entrez votre email" type="text" #emailResetPwd>
                            <br><span [ngClass]="validateEmail?'d-none':'d-block error-message'">Adresse email incorrect</span><br>

                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center">
                                <button type="button" [attr.disabled]="loading?true:null" (click)="sendVerificationCode(emailResetPwd.value)" class="btn-bordered" style="margin-left: auto;margin-right: auto;">
                                    <span [ngClass]="loading?'loading':''"></span> Valider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Forget password end -->

                <!-- Verify Code section Start -->
                <div *ngIf="verifyCode" class="verifyCode  animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro" style="max-width: 600px; margin: auto;">
                            <br><br>
                            <h4 style="font-weight: 400; padding-left: 15px; padding-right: 15px;">
                                Un code de sécurité vous a été envoyé par email. Bien vouloir le saisir dans la case ci-dessous.
                            </h4>
                            <br><br>

                            <input class="aga-email" placeholder="Entrez le code" type="text" #code>

                            <br><a href="#" (click)="sendVerificationCode(newIdentity)" style="display: block; margin-top: 10px;">Renvoyer le code</a><br>
                            <br><span [ngClass]="validateCode?'d-block':'d-none'">Code invalide</span><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="checkCode(code.value)" class="btn-bordered" style="margin-left: auto;margin-right: auto;">
                                    <span [ngClass]="loading?'loading':''"></span> Valider
                                </button>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Verify Code section End -->

                <!-- Create password section Start -->
                <div *ngIf="createPassword" class="createPassword animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <br><br>
                            <h3 style="font-weight: 400">
                                Pour besoin de sécurité, merci de bien vouloir definir votre mot de passe.
                            </h3>
                            <br><br>
                            <input class="aga-email" placeholder="Entrez votre prénom" [value]="pseudoName" [attr.disabled]="changePassword" type="text" #username>
                            <br><span [ngClass]="validateUsername?'d-block':'d-none'">Renseignez votre prénom</span><br>
                            <input class="aga-email" placeholder="Entrez le mot de passe" type="password" #newPassword>
                            <br><span [ngClass]="validateCode?'d-block':'d-none'">Les mots de passe ne correspondent pas</span><br>
                            <input class="aga-email" placeholder="Confirmer le mot de passe" type="password" #confirmPassword>
                            <br><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center">
                                <button type="button" [attr.disabled]="loading?true:null" (click)="changePasswordSubmit(newPassword.value, confirmPassword.value, username.value)" class="btn btn-bordered" style="margin-left: auto;margin-right: auto;">
                                    <span [ngClass]="loading?'loading':''"></span> {{startChat}}
                                </button>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Create password section End -->

                <!-- Create account  Start -->
                <div *ngIf="createAccount" class="createAccount animate__animated animate__slideInRight mx-auto" id="createAccount">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <!-- <app-spinner></app-spinner> -->
                            <h3 class="d-block intro-text">
                                Prenez 30 secondes pour créer votre compte et soyez libre de <b>parler à Aga</b>
                            </h3><br>
                            <input class="aga-email" placeholder="Entrez votre nom*" type="text" #username>
                            <br><span [ngClass]="validateUsername?'d-block':'d-none'">Renseignez votre prénom</span><br>
                            <input class="aga-email" placeholder="Entrez votre email*" type="text" #email>
                            <br><span [ngClass]="validateEmail?'d-none':'d-block error-message'">Adresse email incorrecte</span><br>
                            <input class="aga-email" placeholder="Définir un mot de passe*" [type]="showPassword?'text':'password'" #newPassword style="margin-left:15px">
                            <i [ngClass]="showPassword?'fas fa-eye-slash toggle-password':'fas fa-eye toggle-password'" (click)="togglePassword()" style="color: #000; cursor: pointer;position: relative; right: 25px;top: 0;"></i>
                            <span [ngClass]="validPassword?'d-block':'d-none'"><br>Mot de passe invalide<br></span><br><br>
                            <input type="checkbox" id="cgu"> <label for="cgu" style="padding-left: 6px;">En utilisant Aga, j'accepte les <a href="#" (click)="displayCgu()" >conditions générales d'utilisation.</a></label>
                            <br><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center text-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="createNewAccount(username.value, email.value, newPassword.value)" class="btn-bordered">
                                    <span [ngClass]="loading?'loading':''"></span> Valider
                                </button>
                                <br><a href="#" (click)="backToLogin()" style="display: block; margin-top: 10px;">J'ai un compte</a><br>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Create Account Start -->

                <!-- Intro Start -->
                <div *ngIf="introduction" class="welcome animate__animated animate__slideInRight mx-auto" id="prehome">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro text-left" style="font-size: 20px; padding-left: 20px;">
                            <span style="font-size: 20px;">
                                Bienvenue sur Aga, {{displayName}}. <br>Agacare est un service de bien-être et d’écoute conversationnelle avec​
                            </span>
                            <ul>
                                <li>Du chat –texte avec réponse garantie 24h/24​</li>
                                <li>Des exercices bien-être pour aller mieux et s’élever au quotidien​</li>
                                <li>Des vidéos avec des experts de la santé mentale (si besoin)</li>
                            </ul>
                        </div>
                    </div>

                    <div class="subscribe-form align-items-center text-center">
                        <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                        <button type="button" [attr.disabled]="loading?true:null" (click)="goToChat()" class="btn-bordered">
                            <span [ngClass]="loading?'loading':''"></span> Suivant
                        </button>
                    </div>
                </div>
                <!-- Intro End -->

                <!-- Step 1 start -->
                <div *ngIf="step1" class="welcome animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro text-left" style="font-size: 20px;">
                            <br><br>
                            Afin de mieux personnaliser nos échanges, as tu une idée de ton objectif bien etre?<br>
                            <section style="padding: 40px; margin-left: 80px;">
                                <div class="row" style="text-align: center;">
                                    <div class="col-md-5" style="background-color: #ea8fe5; height: 125px; margin-right: 20px; border-radius: 15px; margin-bottom: 20px;">

                                        <input type="checkbox" name="" class="rightCheckbox" id="depression">
                                        <label for="depression" class="title">Dépression</label>
                                    </div>
                                    <div class="col-md-5" style="background-color: paleturquoise; height: 125px; margin-right: 20px; border-radius: 15px; margin-bottom: 10px;">

                                        <input type="checkbox" name="" class="rightCheckbox" id="anxiete">
                                        <label for="anxiete" class="title">Anxiété</label>
                                    </div>
                                </div>
                                <div class="row" style="text-align:center;">
                                    <div class="col-md-5" style="background-color: #0aa778b3; height: 125px; margin-right: 20px; border-radius: 15px; margin-bottom: 20px;">

                                        <input type="checkbox" name="" class="rightCheckbox" id="confidence">
                                        <label for="confidence" class="title">Confidence</label>
                                    </div>
                                    <div class="col-md-5" style="background-color: #d6d639; height: 125px; margin-right: 10px; border-radius: 15px; margin-bottom: 20px;">
                                        <input type="checkbox" name="" class="rightCheckbox">
                                        <label class="title" for="stress">Stress au boulot</label>
                                    </div>
                                </div>
                                <div class="row" style="text-align:center;">
                                    <div class="col-md-5" style="background-color: #d66e6e; height: 125px; margin-right: 20px; border-radius: 15px; margin-bottom: 20px;">

                                        <input type="checkbox" name="" class="rightCheckbox" id="peur">
                                        <label for="peur" class="title">Peur</label>
                                    </div>
                                    <div class="col-md-5" style="background-color: #2347bbb3; height: 125px; margin-right: 10px; border-radius: 15px; margin-bottom: 20px;">
                                        <input type="checkbox" name="" class="rightCheckbox" id="sommeil">
                                        <label class="title" for="sommeil">Sommeil</label>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <br><br><br>
                    <div class="subscribe-form align-items-center text-center">
                        <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                        <button type="button" [attr.disabled]="loading?true:null" (click)="goToChat()" class="btn-bordered">
                            <span [ngClass]="loading?'loading':''"></span> Suivant
                        </button>
                    </div>
                </div>
                <!-- Step 1 End -->

            </div>
            <div class="notification notification-error" *ngIf="httpError" style="text-align: center;">
                {{errorMessage}}
            </div>
        </div>
    </section>
    <section class="footer" style="position: fixed;bottom: 0; z-index: 99; background-color: #fff;width: 100%;">
        <!-- Shape Bottom -->
        <div class="row">
            <div class="col-md-12" style="width: 60%; margin: auto; text-align: center;">
                <div>
                    <br>
                    <span style="color: #565656; font-family: Poppins ; text-align: center;">
                        <!-- <sup>*</sup> -->
                        En utilisant Aga, vous acceptez nos conditions d'utilisation.
                    </span>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Welcome Area End ***** -->
</div>
