import { Component, OnInit } from '@angular/core';
import {Router,Event,NavigationStart,NavigationEnd} from "@angular/router";
import {HttpService} from "../../services/http.service";
import {DiagnosticService} from "../../services/diagnostic.service";
import {ChatService} from "../../services/chat.service";
import {Message} from "twilio-chat/lib/message";
import {Chat} from "../../interface/chat";
import {SwUpdate} from "@angular/service-worker";
import {PwaService} from "../../services/pwa.service";
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';
import {AlertService} from "../../services/alert.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    title = 'AgaCare';
    public email = '';
    public pseudoName = '';
    public changePassword = false;
    public username = '';
    public displayName: string = '';
    public getChannelBySid;
    public newToken;
    public newChannelSid;
    public newIdentity;
    public getToken;
    public startChat = 'Démarrer le chat';
    public getIdentity;
    public loading = false;
    public prehome = false;
    public welcome = true;
    public createAccount = false;
    public verifyCode = false;
    public hasPassword = false;
    public createPassword = false;
    public validateEmail = true;
    public httpSuccess = false;
    public httpError = false;
    public validateCode = false;
    public validPassword = false;
    public validateUsername = false;
    public forgetPassword = false;
    public cgu = false;
    public successMessage = '';
    public infoMessage = '';
    public errorMessage = '';
    public emptyCreatePassword = false;
    public showPassword = false;
    public messages: Message[] = [];
    public isLoaded:boolean=false;
    public userName = '';
    public introduction = false;
    public step1 = false;
    public diagnostic = false;

    constructor( private router: Router,
                 private chatService: ChatService,
                 public pwaService: PwaService,
                 private spinner: NgxSpinnerService,
                 private httpService: HttpService) {
    }

    ngOnInit(): void {
        this.spinner.show();
        this.homePage();
    }

    start() {

        this.getToken =  localStorage.getItem('agaToken');
        this.getChannelBySid =  localStorage.getItem('channelSid');
        this.getIdentity=  localStorage.getItem('agaIdentity');
        this.displayName=  localStorage.getItem('displayName');
        if(this.getToken && this.getChannelBySid &&  this.getIdentity  ){
            this.router.navigateByUrl('chat');
        }
    }

    async homePage(){
        await this.start();
        await this.spinner.hide();
    }

    createOrJoin(email){
        if(email != '') {
            let channelSid = this.getChannelBySid? this.getChannelBySid : null;
            //console.log('Requete encour de traitement! Veuillez patientez... ');
            this.httpService.post({identity:email,channelSid:channelSid},'chat-token/generate').subscribe(data => {
                //console.log('Done http request!!! ');
                //console.log(data);
                this.httpError = false;
                HomeComponent.storeItems(data).then(next => {
                    this.router.navigateByUrl('chat');
                });
            }, error => {
                //console.log('Done http request!!! ');
                //console.log(error);
                alert('Server Error! Please try again');
            });
        }else{
            alert('Enter email');
        }
    }

    checkPseudo(pseudoName){
        if(pseudoName.length>0){
            this.loading = true;
            this.httpSuccess = false;
            this.httpService.post({nickname:pseudoName},'pseudoLogin').subscribe(data => {
                this.loading = false;
                this.httpError = false;
                console.log(data);
                if(data.success){
                    this.getChannelBySid = data.channelSid;
                    HomeComponent.storeItems(data).then(() =>{
                        this.router.navigateByUrl('chat');
                    });
                }else{
                    //this.httpError = true;
                    //this.errorMessage = data.message;
                    //console.log('johnny ',data);
                    //this.backToLogin();

                    this.httpError = true;
                    this.errorMessage = data.exception;
                    console.log(data);
                }
            }, error => {
                console.log(error);
                    this.errorMessage = error.error.message;
                    this.loading = false;
                    this.httpError = true;
            });
        }else
        alert('Entrez votre pseudo');
    }

    checkMail(email){
        if(HomeComponent.emailValidation(email)){
            this.loading = true;
            this.validateEmail = true;
            this.httpService.post({email:email},'check/email').subscribe(data => {
                this.loading = false;
                this.httpError = false;
                if(data.success){
                    this.welcome = false;
                    if(data.hasAccount && data.user.email_verified_at){
                        this.email = data.user.email;
                        this.username = data.user.name;
                        if(!data.user.password){
                            this.createPassword = true;
                        }else
                            this.hasPassword = true;
                    }else{
                        this.email = data.user.email;
                        this.username = data.user.username;
                        this.verifyCode = true;
                    }
                }
            }, error => {
                this.errorMessage = 'Error occurred while verifying email';
                this.loading = false;
                this.httpError = true;
            });
        } else
            this.validateEmail = false;
    }

    login(email,password){
        this.loading = true;
        this.httpSuccess = false;
        this.httpService.post({email:email,password:password},'login').subscribe(data => {
            this.loading = false;
            this.httpError = false;
            if(data.success){
                this.getChannelBySid = data.channelSid;
                HomeComponent.storeItems(data).then(() =>{
                    this.router.navigateByUrl('chat');
                });
            }else{
                if(data.verifyAccount == true){
                    this.welcome = false;
                    this.verifyCode = true;
                    this.httpError = true;
                    this.errorMessage = data.message;
                    return;
                }
                if(data.diagnostic_ok == false){
                    console.log('User', data.user);
                    console.log('sur le chemin du chat bot avec email = ', data.user.email);
                    HomeComponent.storeItems({token:data.user.token,channelSid:data.user.channelSid, identity:data.user.email, name:data.user.name}).then(() =>{
                        if(data.user.channelSid!==null) {
                            this.router.navigateByUrl('chat');
                        }
                        else
                            this.router.navigateByUrl('chat-bot');
                    });
                }
                this.httpError = true;
                this.errorMessage = data.message;
            }
        }, error => {
            if(error.error.verifyAccount){
                this.welcome = false;
                this.verifyCode = true;
                this.loading = false;
                this.httpSuccess = true;
                this.email = error.error.user.email;
                this.successMessage = error.error.message;
                this.changePassword = false;
            }else{
                this.errorMessage = 'Erreur lors de traitement de vérification';
                this.loading = false;
                this.httpError = true;
            }
        });
    }

    createNewAccount(username, email,password){
        this.loading = true;
        let checkCgu = $('#cgu').is(':checked');
        if(!checkCgu && (email.length == 0 || username.length == 0 || password.length == 0)){
            this.httpError = true;
            this.loading = false;
            this.errorMessage = 'Vous devez renseigner les champs obligatoires et acceptez les conditions générales d\'utilisation';
            return ;
        }
        if(!checkCgu){
            this.httpError = true;
            this.loading = false;
            this.errorMessage = 'Vous devez accepter les conditions générales d\'utilisation';
            return ;
        }
        if(!HomeComponent.emailValidation(email)) {
            this.validateEmail = false;
            this.loading = false;
            return;
        }
        if (password.length < 6) {
            this.httpError = true;
            this.loading = false;
            this.errorMessage = 'Mot de passe doit avoir au moins 6 caractére!';
        } else{
            this.httpError = false;
            this.loading = true;
            this.httpService.post({
                recover:false,
                email:email,
                username: username,
                password: password
            }, 'register').subscribe(data => {
                console.log(data);
                this.httpError = false;
                if(!data.newUser){
                    this.httpError = true;
                    this.loading = false;
                    this.errorMessage = data.message;
                }else{
                    /*this.getChannelBySid = data.channelSid;
                    HomeComponent.storeItems(data).then(() => {
                        this.router.navigateByUrl('chat');
                    });*/
                    this.email = email;
                    this.loading = false;
                    this.createAccount = false;
                    this.verifyCode = true;
                }
            }, error => {
                this.errorMessage = "Une erreur s'est produite, veuillez réessayer";
                this.loading = false;
                this.httpError = true;
                console.log('erreur = ', error);
                //this.createAccount = false;
                //this.verifyCode = true;
            });

            //this.createAccount = false;
           // this.verifyCode = true;
            //this.router.navigateByUrl('introduction');
        }
    }

    changePasswordSubmit(newPassword, confirmPassword,username){
        if(newPassword !== confirmPassword){
            this.errorMessage = 'Mot de passe et confirme mot de passe incorrecte!';
            this.httpError = true;
            this.httpSuccess = false;
        }else {
            if (newPassword.length < 6) {
                this.errorMessage = 'Mot de passe doit avoir au moins 6 caractére!'
            } else
                this.loading = true;
            this.httpService.post({
                email: this.email,
                username: username,
                password: newPassword
            }, 'update-password').subscribe(data => {
                this.loading = false;
                this.httpError = false;
                this.httpSuccess = true;
                this.successMessage = 'Mot de passe changer avec succés';
                this.createPassword = false;
                this.welcome = true;
                this.changePassword = false;
            }, error => {
                this.errorMessage = error.error.message;
                this.loading = false;
                this.httpError = true;
            });
        }
    }

    renewPassword(){
        this.hasPassword = false;
        this.welcome = false;
        this.forgetPassword = true;
        this.changePassword = true;
    }

    newAccount(){
        this.welcome = false;
        this.createAccount = true;
    }

    backToLogin(){
        this.createAccount = false;
        this.prehome = false;
        this.welcome = true;
    }

    backToWelcome(){
        this.forgetPassword = false;
        this.welcome = true;
    }
    backToCreateAccount(){
        this.createAccount = true;
        this.cgu = false;
    }

    togglePassword() {
        this.showPassword = !this.showPassword;
    }

    checkCode(code){
        this.loading = true;
        let suffix = this.changePassword?'recovery/code/verification':'verify-code';

        if(code != ''){
            this.httpService.post({email:this.email,code:code},suffix).subscribe(data => {
                this.httpError = false;
                this.loading = false;
                if(this.changePassword){
                    this.pseudoName = data.user.name;
                    this.successMessage = data.message;
                    this.createPassword = true;
                    this.email = data.user.email;
                    this.verifyCode = false;
                    this.startChat = 'Changer le mot de passe';
                }else{
                    if(data.success){
                        console.log(data);
                        /*this.getChannelBySid = data.user.channelSid;
                        this.newToken = data.user.token;
                        this.newChannelSid = data.user.channelSid;*/
                        this.newIdentity = data.user.email;
                        this.userName = data.user.name;
                        localStorage.setItem('displayName', data.user.name);
                        localStorage.setItem('agaIdentity', data.user.email);
                        /*HomeComponent.storeItems({token:data.user.token,channelSid:data.user.channelSid, identity:data.user.name}).then(() =>{
                            this.router.navigateByUrl('chat');
                        });*/
                        //this.router.navigateByUrl('introduction');
                        this.verifyCode = false;
                        this.introduction = true;
                    }else{
                        this.httpError = true;
                        this.errorMessage = data.message;
                    }
                }
            }, error => {
                this.errorMessage = error.error.message;
                this.loading = false;
                this.httpError = true;
            });
        }
        else{
            this.loading = false;
            this.httpError = true;
            this.errorMessage = "Saisir le code";
        }
    }

    displayCgu(){
        this.createAccount = false;
        this.cgu = true;
    }

    goToCreateAccount(){
        this.createAccount = true;
        this.cgu = false;
    }

    goToChat(){
        this.introduction = false;
        //let data = {token:this.chatToken, identity:this.currentUsername, channelSid: this.currentChannelSid}
        this.router.navigateByUrl('chat-bot');

        /*HomeComponent.storeItems({token:this.newToken,channelSid:this.newChannelSid, identity:this.newIdentity, name: this.displayName}).then(() =>{
            this.router.navigateByUrl('chat');
        });*/
    }

    sendVerificationCode(email){
        if(HomeComponent.emailValidation(email)){
            this.loading = true;
            this.httpService.post({email:email},'send/verification/code').subscribe(data => {
                this.loading = false;
                this.httpError = false;
                this.successMessage = data.message;
                this.changePassword = true;
                this.forgetPassword = false;
                this.email = data.user.email;
                this.pseudoName = data.user.name;
                this.httpSuccess = true;
                this.verifyCode = true;
            }, data => {
                this.errorMessage =data.error.message;
                this.httpSuccess = true;
                this.loading = false;
                this.httpError = true;
            });
        } else
            this.validateEmail = false;
    }
    // @ts-ignore
    static async storeItems(data: Chat){
        localStorage.setItem('agaToken',data.token);
        localStorage.setItem('channelSid',data.channelSid);
        localStorage.setItem('agaIdentity',data.identity);
        localStorage.setItem('displayName',data.name);
        return await true;
    }

    static async removeItems(){
        localStorage.removeItem('agaToken');
        localStorage.removeItem('channelSid');
        localStorage.removeItem('agaIdentity');
        localStorage.removeItem('displayName');
        return await true;
    }

    static emailValidation(email) {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // @ts-ignore
        return regularExpression.test(String(email).toLowerCase());
    }

    static logout(){
        HomeComponent.removeItems().then(()=>{
            // @ts-ignore
            this.router.navigateByUrl('/');
        });
    }

    cancelApp(){
        $('#install-app').hide();
    }
    /*installPwa(): void {
        this.Pwa.promptEvent.prompt();
    }*/
}
