<div class="main">

    <!-- ***** Header End ***** -->

    <!-- ***** Welcome Area Start ***** -->
    <section id="home" class="section welcome-area bg-overlay d-flex align-items-center overflow-hidden">
        <div class="container mx-auto" style="margin-bottom: 110px;">
            <div class="row align-items-center containc">
                <div class="col-12 col-md-12 col-lg-12">
                    <div class="welcome-intro">
                        <img class="navbar-brand-regular" src="/assets/img/logo-aga.png" alt="brand-logo">
                    </div>
                </div>
                <div class="col-md-12 col-12 col-lg-12 " style="text-align: center;">
                    <div class="notification notification-success" *ngIf="httpSuccess" style="text-align: center;">
                        {{successMessage}}
                    </div>
                </div>
                <!-- Welcome Intro Start -->
                <div *ngIf="welcome" class="welcome animate__animated animate__slideInRight mx-auto" id="welcome">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <br><br>
                            <h3 style="font-weight: 400">Juste un message à Aga.<br> Et <b>commencez</b> à vous sentir <b>mieux</b></h3>
                            <br><br>

                            <input class="aga-email" placeholder="Entrez votre email" type="text" #email>
                            <br><span [ngClass]="validateEmail?'d-none':'d-block error-message'">Adresse email incorrect</span><br>
                            <input class="aga-email" placeholder="Entrez le mot de passe" [type]="showPassword?'text':'password'" #password style="margin-left:15px">
                            <i [ngClass]="showPassword?'fas fa-eye-slash toggle-password':'fas fa-eye toggle-password'" (click)="togglePassword()" style="color: #000; cursor: pointer;position: relative; right: 25px;top: 0;"></i>
                            <span [ngClass]="validPassword?'d-block':'d-none'"><br>Mot de passe invalide<br></span>
                            <br><a href="#" (click)="renewPassword()" style="display: block; margin-top: 10px;">Mot de passe oublié?</a><br>

                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center text-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="login(email.value,password.value)" class="btn-bordered">
                                    <span [ngClass]="loading?'loading':''"></span> Parlez à Aga
                                </button>
                                <br><a href="#" (click)="newAccount()" style="display: block; margin-top: 10px;">Créer votre compte</a><br>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Welcome Intro Start -->

                <!-- Create account  Start -->
                <div *ngIf="createAccount" class="createAccount animate__animated animate__slideInRight mx-auto" id="createAccount">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <br><br>
                            <h3 style="font-weight: 400; padding: 3px;">
                                Prenez 30 sécondes pour créer votre compte et soyez libre de <b>parler à Aga</b>
                            </h3>
                            <br><br>
                            <input class="aga-email" placeholder="Entrez votre prénom" type="text" #username>
                            <br><span [ngClass]="validateUsername?'d-block':'d-none'">Renseignez votre prénom</span><br>
                            <input class="aga-email" placeholder="Entrez votre email" type="text" #email>
                            <br><span [ngClass]="validateEmail?'d-none':'d-block error-message'">Adresse email incorrect</span><br>
                            <input class="aga-email" placeholder="Définir un mot de passe" [type]="showPassword?'text':'password'" #newPassword style="margin-left:15px">
                            <i [ngClass]="showPassword?'fas fa-eye-slash toggle-password':'fas fa-eye toggle-password'" (click)="togglePassword()" style="color: #000; cursor: pointer;position: relative; right: 25px;top: 0;"></i>
                            <span [ngClass]="validPassword?'d-block':'d-none'"><br>Mot de passe invalide<br></span>
                            <br><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center text-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="createNewAccount(username.value, email.value, newPassword.value)" class="btn-bordered">
                                    <span [ngClass]="loading?'loading':''"></span> Demarrez_avec_Aga
                                </button>
                                <br><a href="#" (click)="backToLogin()" style="display: block; margin-top: 10px;">J'ai un compte</a><br>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Create Account Start -->

                <!-- Has password section Start -->
                <div *ngIf="hasPassword" class="hasPassword animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro" style="max-width: 600px; margin: auto;">
                            <br><br>
                            <h4 style="font-weight: 400">
                                Entrez votre mot de passe
                            </h4>
                            <br><br>

                            <input class="aga-email" placeholder="Entrez le mot de passe" type="text" #password2>
                            <span [ngClass]="validPassword?'d-block':'d-none'"><br>Mot de passe invalide<br></span>
                            <br><a href="#" (click)="renewPassword()" style="display: block; margin-top: 10px;">Mot de passe oublié?</a><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="login(true,password2.value)" class="btn-bordered" style="margin-left: auto;margin-right: auto;">
                                    <span [ngClass]="loading?'loading':''"></span> Demarrer le chat
                                </button>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Has password section End -->

                <!-- Forget password start -->
                <div *ngIf="forgetPassword" class="forgetPassword  animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <br><br>
                            <div style="width:100%" class=" row text-left">
                                <div class="col-md-12 ml-4">
                                    <button (click)="backToWelcome()" class="btn-outline-primary " style="padding: 2px 20px;;"> Retour </button>
                                </div>
                            </div>
                            <br>
                            <h3 style="font-weight: 400">Entrez votre adresse email afin de réinitialiser votre mot de passe</h3>
                            <br><br>

                            <input class="aga-email" placeholder="Entrez votre email" type="text" #emailResetPwd>
                            <br><span [ngClass]="validateEmail?'d-none':'d-block error-message'">Adresse email incorrect</span><br>

                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center">
                                <button type="button" [attr.disabled]="loading?true:null" (click)="sendVerificationCode(emailResetPwd.value)" class="btn-bordered" style="margin-left: auto;margin-right: auto;">
                                    <span [ngClass]="loading?'loading':''"></span> Valider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Forget password end -->

                <!-- Verify Code section Start -->
                <div *ngIf="verifyCode" class="verifyCode  animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro" style="max-width: 600px; margin: auto;">
                            <br><br>
                            <h4 style="font-weight: 400">
                                Un code de sécurité vous a été envoyé par email. Bien vouloir le saisir dans la case ci-dessous.
                            </h4>
                            <br><br>

                            <input class="aga-email" placeholder="Entrez le code" type="text" #code>
                            <br><span [ngClass]="validateCode?'d-block':'d-none'">Code invalide</span><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center">
                                <!--<input type="email" class="form-control" placeholder="Entrer votre Email">-->
                                <button type="button" [attr.disabled]="loading?true:null" (click)="checkCode(code.value)" class="btn-bordered" style="margin-left: auto;margin-right: auto;">
                                    <span [ngClass]="loading?'loading':''"></span> Valider
                                </button>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Verify Code section End -->

                <!-- Create password section Start -->
                <div *ngIf="createPassword" class="createPassword animate__animated animate__slideInRight mx-auto">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="welcome-intro">
                            <br><br>
                            <h3 style="font-weight: 400">
                                Pour besoin de sécurité, merci de bien vouloir definir votre mot de passe.
                            </h3>
                            <br><br>
                            <input class="aga-email" placeholder="Entrez votre prénom" [value]="pseudoName" [attr.disabled]="changePassword" type="text" #username>
                            <br><span [ngClass]="validateUsername?'d-block':'d-none'">Renseignez votre prénom</span><br>
                            <input class="aga-email" placeholder="Entrez le mot de passe" type="password" #newPassword>
                            <br><span [ngClass]="validateCode?'d-block':'d-none'">Les mots de passe ne correspondent pas</span><br>
                            <input class="aga-email" placeholder="Confirmer le mot de passe" type="password" #confirmPassword>
                            <br><br>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form align-items-center">
                                <button type="button" [attr.disabled]="loading?true:null" (click)="changePasswordSubmit(newPassword.value, confirmPassword.value, username.value)" class="btn btn-bordered" style="margin-left: auto;margin-right: auto;">
                                    <span [ngClass]="loading?'loading':''"></span> {{startChat}}
                                </button>
                            </div>
                            <!--<div>
                                <i style="font-size: 13px;">&nbsp; Disponible sur facebook Messenger</i>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- Create password section End -->

            </div>
            <div class="notification notification-error" *ngIf="httpError" style="text-align: center;">
                {{errorMessage}}
            </div>
        </div>
    </section>
    <section class="footer" style="position: fixed;bottom: 0; z-index: 99; background-color: #fff;width: 100%;">
        <!-- Shape Bottom -->
        <div class="row">
            <div class="col-md-12" style="width: 60%; margin: auto; text-align: center;">
                <div>
                    <br>
                    <span style="color: #565656; font-family: Poppins ; text-align: center;">
                        <!-- <sup>*</sup> -->
                        En utilisant Aga, vous acceptez nos conditions d'utilisation.
                    </span>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Welcome Area End ***** -->
</div>